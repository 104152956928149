import { Button, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useState } from "react";
import ConfirmCancelChangesDialog from "../../../commons/dialog/confirmCancelChangesDialog";
import AddAppointment from "../../accident/accidentDashboard/appointmentsCalendar/addAppointment";
import AppointmentSession from "../../accident/accidentDashboard/appointmentsCalendar/session/appointmentSession";
import FpaAppointmentCard from "./fpaAppointmentCard";

// Accident contiene siniestro (imagino que es id historia clinica) y trabajador

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
    },

}));

const formatAppointment = (appointment) => {
    return appointment = {
        ...appointment,
        fromDB: false,
    };
}

const FpaAppointment = ({ idArt, idSiniestro, trabajador, disabled }) => {
    const classes = useStyles();

    const { setFieldValue, values } = useFormikContext();
    const [openAppointmentSession, setOpenAppointmentSession] = useState(false);
    const [openAddAppointment, setOpenAddAppointment] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [appointmentsCollection, setAppointmentsCollection] = useState(false);
    const [appointment, setAppointment] = useState();



    const handleSubmitSessions = (appointments) => {
        setFieldValue('turnos', appointments.map(a => formatAppointment(a)));
        setOpenAppointmentSession(false);
    }

    const handleSubmitAppointment = (appointment) => {
        if (appointmentsCollection) {
            setAppointment(appointment);
            setOpenAppointmentSession(true);
            setOpenAddAppointment(false);
            setAppointmentsCollection(false);
        } else {
            setFieldValue('turnos', [formatAppointment(appointment)]);
            setOpenAddAppointment(false);
        }
    }
    const handleDeleteAppointment = (idAppointment) => {
        let auxAppointment = values.turnos[idAppointment];
        auxAppointment.DesvincularNroTransaccionFpa = true;
        let appointments = [...values.turnos]
        appointments.splice(idAppointment, 1);
        setFieldValue('turnos', appointments);
        if (auxAppointment.fromDB) {

            let auxAppointments = [...values.turnosADesvincular];
            auxAppointments.push(auxAppointment);
            // Agrego a la lista de turnos a desasociar de fpa cuando se guarde
            setFieldValue(`turnosADesvincular`, auxAppointments);
        }


    }

    const handleCloseAll = () => {
        setOpenAddAppointment(false);
        setOpenAppointmentSession(false);
        setConfirmDialogOpen(false);
    }
    return (
        <div className={classes.container}>
            <AddAppointment
                open={openAddAppointment}
                handleClose={() => setOpenAddAppointment(false)}
                handleOpenConfirmDialog={() => setConfirmDialogOpen(true)}
                handleSubmit={handleSubmitAppointment}
                artId={idArt}
                openInCalendar
                openAppointmentCollection={() => setAppointmentsCollection(true)}
            />

            <AppointmentSession
                appointment={appointment}
                open={openAppointmentSession}
                handleClose={() => setOpenAppointmentSession(false)}
                handleOpenConfirmDialog={() => setConfirmDialogOpen(true)}
                artId={idArt}
                siniestro={idSiniestro}
                trabajador={trabajador}
                handleSubmit={handleSubmitSessions}
            />
            <ConfirmCancelChangesDialog
                open={confirmDialogOpen}
                handleClose={() => setConfirmDialogOpen(false)}
                handleConfirm={handleCloseAll}
            />
            <FpaAppointmentCard
                turnos={values.turnos}
                disabled={disabled}
                handleDelete={handleDeleteAppointment}
            />
            {values.turnos.length == 0 &&
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenAddAppointment(true)}
                    disabled={disabled}>
                    Agregar turno
                </Button>
            }
        </div>

    )
};

export default FpaAppointment;