import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentsCalendar } from "../../../../../../actions/accidentDashboard";
import {
  attendedAppointment,
  cancelledAppointment,
  getAppointmentSession,
  postAppointment,
  unAttendedAppointment,
  updateAppointment,
} from "../../../../../../actions/appointments";
import { openNewForm } from "../../../../../../actions/formsState";
import {
  downloadAppointmentSessionReport,
  getAppointmentSessionReportBase64,
} from "../../../../../../actions/reports";
import { convertDateTimeToNet } from "../../../../../../utils/datetimeUtils";
import { downloadFile } from "../../../../../../utils/filesUtils";
import snackbarUtils from "../../../../../../utils/snackbarUtils";
import PrintAndDownloadDialog from "../../../../../commons/dialog/PrintAndDownloadDialog";
import PdfView from "../../../../../commons/file/pdfView";
import AddAppointment from "../addAppointment";
import AppointmentPrintSession from "../session/appointmentPrintSession";
import AppointmentDetailCards from "./appointmentDetailCards";

const useStyles = makeStyles((theme) => ({
  appointmentsContainer: {
    display: "flex",
    padding: 0,
    backgroundColor: "#f5f5f5",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginLeft: 10,
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
  },
}));

const AppointmentDetail = ({
  data,
  open,
  handleClose,
  handleOpenConfirmDialog,
  openEdit,
  handleCloseEdit,
  handleOpenEdit,
  appointment,
  cargaFPT,
  idArt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const reportSession = useSelector(
    (state) => state.reports.appointmentSession,
  );
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  const [appointmentsIds, setAppointmentsIds] = useState([]);
  const [openPrintSession, setopenPrintSession] = useState(false);
  const [openReportSession, setopenReportSession] = useState(false);
  const mlCloudCoreArtId =
    appointment.mlCloudCoreArtId || accident.mlCloudCoreArtId;
  const idSiniestro = appointment.idSiniestro || accident.idSiniestro;

  const attended = (appointment) => {
    dispatch(
      attendedAppointment(appointment.idTurnoSiniestro, mlCloudCoreArtId),
    ).then(() => {
      dispatch(getAppointmentsCalendar(idSiniestro, mlCloudCoreArtId));
      handleClose();
      snackbarUtils.success("Turno marcado como concurrido correctamente");
    });
  };

  const unAttended = (appointment) => {
    dispatch(
      unAttendedAppointment(appointment.idTurnoSiniestro, mlCloudCoreArtId),
    ).then(() => {
      dispatch(getAppointmentsCalendar(idSiniestro, mlCloudCoreArtId));
      handleClose();
      snackbarUtils.success("Turno marcado como ausente correctamente");
    });
  };

  const cancelled = (appointment) => {
    dispatch(
      cancelledAppointment(appointment.idTurnoSiniestro, mlCloudCoreArtId),
    ).then(() => {
      dispatch(getAppointmentsCalendar(idSiniestro, mlCloudCoreArtId));
      handleClose();
      snackbarUtils.success("Turno anulado correctamente");
    });
  };

  const handleEditAppointment = (
    values,
    createProfessional,
    isUpdateAppointment,
  ) => {
    const model = {
      ...values,
      fechaHora: convertDateTimeToNet(values.fechaHora),
      traslado: values.traslado ? values.traslado : false,
    };

    if (isUpdateAppointment) {
      dispatch(
        updateAppointment(values.idTurnoSiniestro, mlCloudCoreArtId, model),
      ).then(() => {
        dispatch(getAppointmentsCalendar(idSiniestro, mlCloudCoreArtId));
        snackbarUtils.success("Turno modificado correctamente");
      });
    } else {
      dispatch(
        cancelledAppointment(values.idTurnoSiniestro, mlCloudCoreArtId),
      ).then(() => {
        const { especialidadNombre, estado, idTurnoSiniestro, ...newModel } =
          model;

        dispatch(postAppointment(newModel)).then(() => {
          dispatch(getAppointmentsCalendar(idSiniestro, mlCloudCoreArtId));
        });
      });
      snackbarUtils.success("Se creo el nuevo turno correctamente");
    }

    if (cargaFPT && !isUpdateAppointment && values.traslado) {
      dispatch(
        openNewForm("Fpt", "Formulario de Pedido de Traslado", {
          professionalName: values.profesionalApellidoNombre,
          specialityName: values.especialidadNombre,
          appointmentDates: [values.fechaHora],
          appointmentsIds: [values.idTurnoSiniestro],
        }),
      );
    }
    handleCloseEdit();
    handleClose();
  };

  const handleOpenPrintSession = (appointment) => {
    dispatch(
      getAppointmentSession(
        appointment.idTurnoSiniestro,
        appointment.mlCloudCoreArtId,
      ),
    );
    setopenPrintSession(true);
  };

  const handleClosePrintSession = () => {
    setopenPrintSession(false);
  };

  const handleSubmitPrintSession = (data) => {
    const appointmentIds = [];
    data.forEach((t) => {
      appointmentIds.push(t.id);
    });
    setAppointmentsIds(appointmentIds);
    dispatch(getAppointmentSessionReportBase64(appointmentIds, idArt));
    setopenReportSession(true);
  };

  const handleCloseReportSession = () => {
    setopenReportSession(false);
    handleClosePrintSession();
    handleClose();
    setAppointmentsIds([]);
  };

  const handleDownloadSessionReport = () => {
    dispatch(downloadAppointmentSessionReport(appointmentsIds, idArt)).then(
      (response) => {
        downloadFile(response.value.data, "Reporte Sesión de Turnos.pdf");
      },
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>TURNOS</Typography>
          <Tooltip title="Cerrar">
            <Close onClick={handleClose} className={classes.closeIcon} />
          </Tooltip>
        </div>
        <DialogContent className={classes.appointmentsContainer}>
          <AppointmentDetailCards
            data={data}
            onAttended={attended}
            onUnAttended={unAttended}
            onEdit={handleOpenEdit}
            onCancelled={cancelled}
            onPrintSession={handleOpenPrintSession}
          />
        </DialogContent>
      </Dialog>
      <AppointmentPrintSession
        open={openPrintSession}
        handleClose={handleClosePrintSession}
        handleSubmit={handleSubmitPrintSession}
      />
      <PrintAndDownloadDialog
        maxWidth="lg"
        openModal={openReportSession}
        handleCloseModal={handleCloseReportSession}
        childComponent={
          reportSession.data.base64 && (
            <PdfView
              file={`data:application/pdf;base64,${reportSession.data.base64}`}
            />
          )
        }
        loading={reportSession.isFetching}
        title="REPORTE SESIÓN DE TURNOS"
        reactToPrintProps={{ suppressErrors: true }}
        handleDownload={handleDownloadSessionReport}
      />
      <AddAppointment
        open={openEdit}
        handleOpenConfirmDialog={handleOpenConfirmDialog}
        handleSubmit={handleEditAppointment}
        appointmentModel={appointment}
        artId={mlCloudCoreArtId}
        documentation={documentation}
        openInCalendar={false}
        enablePastDates={false}
        appointmentDetail={true}
      />
    </div>
  );
};

export default AppointmentDetail;
