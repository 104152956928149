import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { attendanceQr } from "../../../../../actions/appointments";
import Attendance from "./attendance";

import { setAttendanceDocumentNumber } from "../../../../../actions/login";
import snackbarUtils from "../../../../../utils/snackbarUtils";


const initialValues = {
  documento: "",
}

const AttendanceContainer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prestador = queryParams.get("provider");
  const sucursal = queryParams.get("sucursal");
  const documentNumber = useSelector(state => state.auth.documentNumberAttendance);
  const attendanceQrState = useSelector(state => state.appointments.attendanceQr)
  const dispatch = useDispatch();



  const [formValues, setFormValues] = useState({
    ...initialValues,
    documento: documentNumber ?? initialValues.documento, // Para autoenviar el form
  })

  const [deviceLocation, setDeviceLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const handleSubmit = (form) => {
    dispatch(attendanceQr(form.documento, prestador, sucursal, deviceLocation.latitude, deviceLocation.longitude)).then((res) => {
      snackbarUtils.success("Usted ha dado la asistencia a los Turnos del día");
      dispatch(setAttendanceDocumentNumber(form.documento));
    })
  }

  // Obtener la ubicación (geolocalización)
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setDeviceLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error geolocalizacion", error.message);
        },
      );
    }
  }, []);

  return (
    <>
      <Attendance
        initialValues={formValues}
        handleSubmit={handleSubmit}
        deviceLocation={deviceLocation}
        documentNumber={documentNumber}
        loading={attendanceQrState.isFetching}
      />
    </>
  );
};

export default AttendanceContainer;
