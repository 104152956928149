import { Card, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Loop } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    height: "260px",
    margin: "20px",
    marginTop: "10px",
  },
  lodash: {
    padding: "1px",
    marginTop: "2px",
  },
  containerCard: {
    flex: 1,
    display: "flex",
    maxWidth: "500px",
    margin: "16px 16px 16px 16px",
  },
  containerIconRefresh: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: ({ didInvalidate }) =>
      !didInvalidate ? "flex-end" : "space-between",
    marginTop: "8px",
    marginRight: "8px",
  },
  card: {
    borderRadius: "6px",
    flex: 1,
    boxShadow: "1px 1px 4px #808080e0",
  },
  cardText: {
    display: "flex",
    margin: "15px 24px 24px 24px",
    padding: "3px",
    color: "gray",
    fontFamily: "Roboto, sans-serif",
    fontSize: "10px",
    fontWeight: "bold",
    borderBottom: "1px gray solid",
  },
  error: {
    height: "14px",
    width: "14px",
    backgroundColor: "red",
    borderRadius: "50px",
    border: "2px solid #f1f1f1",
    marginLeft: "15px",
  },
  icon: {
    cursor: "pointer",
    color: "#818181",

    "&:hover, &:focus": {
      color: "#145a8c",
    },
    "&:active": {
      color: "#2c148c",
    },
  },
  iconButton: {
    padding: 3,
  },
}));

const CardDasboard = ({
  component: ComponentDashboard,
  text,
  fetchData,
  isFetching,
  didInvalidate,
}) => {
  const classes = useStyles({ didInvalidate });

  return (
    <div className={classes.containerCard}>
      <Card className={classes.card}>
        <div className={classes.containerIconRefresh}>
          {didInvalidate && (
            <Tooltip title="Error en la consulta">
              <div className={classes.error} />
            </Tooltip>
          )}

          {isFetching ? (
            <div className={classes.lodash}>
              <CircularProgress size={21} />
            </div>
          ) : (
            <IconButton className={classes.iconButton} onClick={fetchData}>
              <Loop className={classes.icon} />
            </IconButton>
          )}
        </div>

        <div className={classes.dashboard}>{ComponentDashboard}</div>

        <div className={classes.cardText}>{text}</div>
      </Card>
    </div>
  );
};

export default CardDasboard;
