import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import history from "../../history";
import AuthRoutes from "./authRoutes";
import PublicRoutes from "./publicRoutes";

const RoutesContainer = () => {
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.defaults.baseURL = CONFIG.baseUrl;
    axios.defaults.withCredentials = true;
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
    setLoading(false);
  }, []);

  if (loading) return <div />;

  return (
    <BrowserRouter basename={CONFIG.path} history={history}>
      {!token ? <PublicRoutes /> : <AuthRoutes />}
    </BrowserRouter>
  );
};

export default RoutesContainer;
