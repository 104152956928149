import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpOutline } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: 5,
    color: "#858a93",
  },
  iconContainer: {
    display: "flex",
  },
}));

const IconToJira = () => {
  const handleClickHelp = () => {
    window.open(
      "https://accessinformatica.atlassian.net/servicedesk/customer/portals",
    );
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.iconContainer}>
        <Tooltip title="Ayuda" arrow>
          <IconButton
            size="small"
            onClick={handleClickHelp}
            className={classes.icon}>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default IconToJira;
