import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Print } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as QRCodeLib from "qrcode"; // Import the qrcode library for generating the QR as Base64
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code"; // Import react-qr-code for rendering the QR on screen
import { useSelector } from "react-redux";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FeatureLabel from "../../../commons/labels/FeatureLabel";
import CircularLoading from "../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    width: "100%",
  },
  autocomplete: {
    width: "35%",
  },
  title: {
    color: "#484848",
    marginBottom: "8px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    marginBottom: 20,
  },
  qrContainer: {
    textAlign: "center",
    marginTop: "50px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  errorText: {
    color: "red",
    marginTop: "8px",
  },
  button: {
    marginLeft: 20,
  },
}));

const QRForm = ({ handlePrintFile }) => {
  const classes = useStyles();
  const url = window.location.origin;
  const provider = useSelector((state) => state.providers.selectedProvider);
  const branches = useSelector((state) => state.appointments.getBranches);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [qrVisible, setQrVisible] = useState(false);
  const [qrBase64, setQrBase64] = useState(null);

  useEffect(() => {
    if (branches.data.length === 1) {
      setSelectedBranch(branches.data[0].id);
      setQrVisible(true);
    }
  }, [branches.data]);

  const initialValues = {
    sucursal: branches.data?.length === 1 ? branches.data[0].id : null,
  };

  const handleGenerateQR = (value) => {
    setSelectedBranch(value.sucursal);
    setQrVisible(true);
  };

  const handlePrint = () => {
    const qr = `${url}${CONFIG.path}/attendance?provider=${provider}&sucursal=${selectedBranch}`;
    generateQrBase64(qr);
  };

  const generateQrBase64 = (value) => {
    QRCodeLib.toDataURL(value, { width: 256, margin: 0 }, (error, url) => {
      handlePrintFile(url, selectedBranch);
    });
  };

  return (
    <>
      <div className={classes.title}>
        Asistencia a turnos mediante codigo QR
      </div>
      <Paper className={classes.paper}>
        {!branches.isFetching ? (
          branches.data?.length === 0 ? (
            <Typography className={classes.errorText}>
              No se hallaron sucursales asociadas al Prestador. Notifique al
              administrador de Sistema.
            </Typography>
          ) : (
            <>
              <Formik initialValues={initialValues} onSubmit={handleGenerateQR}>
                {({ setFieldValue }) => (
                  <Form className={classes.formContainer}>
                    <FormikAutocomplete
                      name="sucursal"
                      label="Sucursal"
                      options={branches.data}
                      disabled={branches.data.length === 1}
                      value={selectedBranch}
                      callBack={(event, newValue) => {
                        if (newValue) {
                          setQrVisible(true);
                        } else {
                          setQrVisible(false);
                        }
                      }}
                      className={classes.autocomplete}
                      renderOption={(option) => `${option.nombre}`}
                    />
                    <Button
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      color="primary"
                      disabled={branches.data?.length === 1}>
                      Generar QR
                    </Button>
                  </Form>
                )}
              </Formik>
              {qrVisible && selectedBranch ? (
                <>
                  <div className={classes.qrContainer}>
                    <QRCode
                      value={`${url}${CONFIG.path}/attendance?provider=${provider}&sucursal=${selectedBranch}`}
                      size={256}
                      bgColor="#ffffff"
                      fgColor="#000000"
                    />
                  </div>
                  <Grid
                    container
                    spacing={1}
                    className={classes.buttonContainer}>
                    <Grid item>
                      <FeatureLabel
                        labelText="IMPRIMIR"
                        icon={<Print />}
                        onClick={handlePrint}
                        tooltipText="Imprimir archivo"
                        disabled={!qrVisible}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 50,
                  }}>
                  <Typography>
                    Seleccione una sucursal para generar el QR
                  </Typography>
                </div>
              )}
            </>
          )
        ) : (
          <CircularLoading />
        )}
      </Paper>
    </>
  );
};

export default QRForm;
