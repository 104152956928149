import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FileCopy } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postImportIet } from "../../../../actions/importForms";
import snackbarUtils from "../../../../utils/snackbarUtils";
import FeatureLabel from "../../../commons/labels/FeatureLabel";
import ImportIetError from "./errors/ImportIetError";
import ImportIetForm from "./forms/ImportIetForm";

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
  },
  paper: {
    display: "flex",
    padding: 20,
  },
  processingFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

const ImportIetContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [importIetFile, setImportIetFile] = useState([]);
  const [errorImportarIet, setErrorImportarIet] = useState(null);
  const [procesandoArchivoImportacion, setProcesandoArchivoImportacion] =
    useState(false);
  const [archivoErroresImportacion, setArchivoErroresImportacion] =
    useState(null);

  const handleUploadIet = (values) => {
    setProcesandoArchivoImportacion(true);

    if (importIetFile.length > 0) {
      const adjuntos = {
        base64: importIetFile[0].value,
        nombre: importIetFile[0].filename,
      };

      dispatch(postImportIet(values.artId, adjuntos))
        .then((res) => {
          if (res.value.data.indexOf("exitosamente") !== -1) {
            snackbarUtils.success(res.value.data);
            setImportIetFile([]);
          } else {
            setArchivoErroresImportacion(res.value.data);
            setErrorImportarIet(true);
          }

          setProcesandoArchivoImportacion(false);
        })
        .catch(() => {
          setProcesandoArchivoImportacion(false);
          setErrorImportarIet(true);
        });
    }
  };

  const handleExit = () => {
    navigate("/home/");
  };

  const handleResetImport = () => {
    setErrorImportarIet(false);
    setArchivoErroresImportacion(null);
    setImportIetFile([]);
  };

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        Importar Informe de Evolución y Tratamiento
      </Typography>
      <Paper className={classes.paper}>
        {!procesandoArchivoImportacion ? (
          <>
            {!errorImportarIet && (
              <ImportIetForm
                handleSubmit={handleUploadIet}
                file={importIetFile}
                setFile={setImportIetFile}
              />
            )}
            {errorImportarIet && (
              <ImportIetError
                handleExit={handleExit}
                handleReset={handleResetImport}
                file={archivoErroresImportacion}
              />
            )}
          </>
        ) : (
          <div className={classes.processingFile}>
            <FeatureLabel
              labelText="Procesando archivo"
              icon={<FileCopy />}
              onClick={() => {}}
              tooltipText="Procesando archivo"
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default ImportIetContainer;
