import { makeStyles } from "@material-ui/core";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentInRange,
  getAppointmentsCalendar,
} from "../../../../../actions/accidentDashboard";
import { postAppointment } from "../../../../../actions/appointments";
import { openNewForm } from "../../../../../actions/formsState";
import { validateDateRange } from "../../../../../utils/datetimeUtils";
import snackbarUtils from "../../../../../utils/snackbarUtils";
import ConfirmDialog from "../../../../commons/dialog/confirmDialog";
import CardTitle from "../cardTitle";
import AddAppointment from "./addAppointment";
import Calendar from "./calendar";
import AppointmentDetail from "./detail/appointmentDetail";
import AppointmentDetailFromForm from "./detailFromForm/appointmentDetailFromForm";
import "./reactCalendar.css";
import AppointmentSession from "./session/appointmentSession";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const initialAppointment = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  asistio: false,
};

const AppointmentsCalendar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const model = useSelector((state) => state.forms.model);
  const esPrestador = useSelector((state) => state.auth.roles?.PRESTADOR);

  const [openAdd, setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [appointmentsCollection, setAppointmentsCollection] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [appointmentsSelected, setAppointmentsSelected] = useState([]);
  const [appointmentModel, setAppointmentModel] = useState(initialAppointment);
  const [openFromForm, setOpenFromForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const cargaFPT = documentation.data.find((f) => f.formulario === "Fpt")
    ? true
    : false;

  const disabled = !calendar.data.isCalendarEnabled;

  useEffect(() => {
    callGetAppointmentsCalendar();
  }, []);

  useEffect(() => {
    if (openFromForm) {
      setAppointmentsSelected(() =>
        calendar.data.appointments.filter(
          (d) =>
            validateDateRange(d.fechaHora, {}, appointmentsPostForm, model) &&
            (d.estado === "Programados" || d.estado === "No informados"),
        ),
      );
    }
  }, [openFromForm]);

  useEffect(() => {
    if (appointmentsPostForm.isOpen) {
      setOpenFromForm(true);
    }
  }, [appointmentsPostForm.isOpen]);

  const handleOpenEdit = (appointment) => {
    setAppointment(appointment);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setAppointment({});
    setOpenEdit(false);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleClearOpenFromForm = () => {
    setOpenFromForm(false);
    dispatch(clearAppointmentInRange());
    setAppointmentsSelected([]);
  };

  const handleCloseAllDetails = () => {
    handleClearOpenFromForm();
    handleCloseSession();
    handleCloseDetail();
    handleCloseAdd();
    handleCloseEdit();
    handleCloseConfirmDialog();
  };

  const callGetAppointmentsCalendar = () => {
    dispatch(
      getAppointmentsCalendar(accident.idSiniestro, accident.mlCloudCoreArtId),
    );
  };

  const handleOpenAppointmentcollection = () => {
    setAppointmentsCollection(true);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setAppointmentModel(initialAppointment);
  };

  const handleSubmitAdd = (
    appointment,
    createProfessional,
    isUpdateAppointment,
  ) => {
    if (appointmentsCollection) {
      setAppointment(appointment);
      setOpenSession(true);
      handleCloseAdd();
      setAppointmentsCollection(false);
    } else {
      appointment = {
        ...appointment,
        fechaHora: moment(appointment.fechaHora).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
        ),
      };
      dispatch(postAppointment(appointment)).then((res) => {
        dispatch(
          getAppointmentsCalendar(
            accident.idSiniestro,
            accident.mlCloudCoreArtId,
          ),
        );
        handleCloseAdd();
        setAppointmentsCollection(false);
        snackbarUtils.success("Turno enviado correctamente");
        if (
          cargaFPT &&
          appointment.traslado &&
          new Date(appointment.fechaHora) >= new Date()
        ) {
          dispatch(
            openNewForm("Fpt", "Formulario de Pedido de Traslado", {
              professionalName: appointment.profesionalApellidoNombre,
              specialityName: appointment.especialidadNombre,
              appointmentDates: [appointment.fechaHora],
              appointmentsIds: [res?.value?.data?.idTurnoSiniestro],
            }),
          );
        }
      });
    }
  };

  const handleClickDay = (appointments, date) => {
    if (appointments.length) {
      setAppointmentsSelected(appointments);
      setOpenDetail(true);
    } else if (esPrestador) {
      handleOpenAdd();
      setAppointmentModel({ ...initialAppointment, fechaHora: date });
    }
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    handleClearOpenFromForm();
  };

  const handleCloseSession = () => {
    setOpenSession(false);
  };

  const icons = [
    {
      Icon: AddCircleOutline,
      tooltip: "Agregar turno",
      onClick: handleOpenAdd,
      disabled: disabled,
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <CardTitle
          title="TURNOS"
          color="secondary"
          icons={esPrestador ? icons : []}
        />
        <Calendar
          data={calendar.data.appointments}
          loading={calendar.isFetching || !calendar.data.appointments}
          handleClickDay={handleClickDay}
          disabled={disabled}
        />
        <AddAppointment
          open={openAdd}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleSubmit={handleSubmitAdd}
          appointmentModel={appointmentModel}
          artId={accident.mlCloudCoreArtId}
          handleCloseDialog={handleOpenConfirmDialog}
          openAppointmentCollection={handleOpenAppointmentcollection}
          openInCalendar={true}
          documentation={documentation}
        />
        <AppointmentDetail
          data={appointmentsSelected}
          open={openDetail && !model.isFetching}
          handleClose={handleCloseDetail}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          openEdit={openEdit}
          handleOpenEdit={handleOpenEdit}
          handleCloseEdit={handleCloseEdit}
          appointment={appointment}
          cargaFPT={cargaFPT}
        />
        <AppointmentSession
          appointment={appointment}
          open={openSession}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleClose={handleCloseSession}
          accident={accident}
          artId={accident.mlCloudCoreArtId}
          siniestro={accident.idSiniestro}
          trabajador={accident.apellidoTrabajador + ' ' + accident.nombreTrabajador}
          nroFormulario={null}
          callBack={callGetAppointmentsCalendar}
          hasFpt={cargaFPT}
        />
        <AppointmentDetailFromForm
          appointmentsSelected={appointmentsSelected}
          handleClose={handleCloseDetail}
          open={openFromForm}
          formTitle="Se han encontrado Turnos cercanos a la Fecha de Atención ingresada. En caso de que alguno corresponda a esta atención, seleccione el mismo."
        />
      </div>
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseAllDetails}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
    </>
  );
};

export default AppointmentsCalendar;
