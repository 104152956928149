import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccidentLogs } from "../../../../actions/accidents"; // Acción de Redux para obtener logs.
import CircularLoading from "../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  dialogTitle: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  noData: {
    textAlign: "center",
    padding: "16px",
  },
}));

const EditLogsContainer = ({ accident, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const logs = useSelector((state) => state.accidents.logs);
  useEffect(() => {
    if (accident) {
      // Despacha la acción para obtener los logs.
      dispatch(
        getAccidentLogs(accident.mlCloudCoreArtId, accident.idSiniestro),
      );
    }
  }, [accident]);

  return (
    <Dialog
      open={accident ? true : false}
      onClose={handleClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle className={classes.dialogTitle}>
        Historial de Cambios
      </DialogTitle>
      <DialogContent>
        {logs.isFetching ? (
          <CircularLoading />
        ) : logs.data.length ? (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Número Siniestro ART</TableCell>
                  <TableCell>Fecha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.data.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.numeroSiniestroArt}</TableCell>
                    <TableCell>
                      {new Date(log.fecha).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography className={classes.noData}>
            No hay datos disponibles.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLogsContainer;
