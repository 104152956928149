import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAnsweredAuthorizationsNotification } from "../../actions/authorizations";
import { getConnectivityErrorsNotProcessedCount } from "../../actions/connectivityResponse";
import { getUserProviders } from "../../actions/providers";
import { changeDrawerStatus } from "../../actions/userPreferences";
import Drawer from "../commons/drawer/drawer";
import Error from "../commons/error/error";
import Header from "../commons/header/header";
import LoadingComponent from "../commons/loadingData/loading";
import ImportIetContainer from "../ui/accident/importIet/ImportIetContainer";
import Accident from "../ui/accident/inProgress/accidentContainer";
import NewAccidentsContainer from "../ui/accident/newAccident/newAccidentContainer";
import SearchContainer from "../ui/accident/search/searchContainer";
import AuthorizedAttendanceContainer from "../ui/appointment/attendance/authorizedAttendance/authorizedAttendanceContainer";
import QRContainer from "../ui/appointment/attendance/QRContainer";
import PendingAppointmentsContainer from "../ui/appointment/pending/pendingContainer";
import AppointmentsSearchContainer from "../ui/appointment/search/searchContainer";
import SupportAppointmentsContainer from "../ui/appointment/support/appointmentsSearchContainer";
import PrestadorContainer from "../ui/art/prestadores/prestadorContainer";
import ArtSearchContainer from "../ui/art/siniestros/siniestroContainer";
import AnsweredContainer from "../ui/authorizations/answered/answeredContainer";
import PendingAuthorizationsContainer from "../ui/authorizations/pending/pendingAuthorizationsContainer";
import AuthorizationsSearchContainer from "../ui/authorizations/search/searchContainer";
import ConnectivityErrorsContainer from "../ui/connectivityResponses/errors/ConnectivityErrorsContainer";
import SearchConnectivityErrorsContainer from "../ui/connectivityResponses/search/SearchConnectivityErrorsContainer";
import DashboardCrudArt from "../ui/crudArt/dashboardCrudArtContainer";
import DashboardContainer from "../ui/dashboard/container";
import EmailContainer from "../ui/email/emailContainer";
import FilesContainer from "../ui/files/FilesContainer";
import MyArtsContainer from "../ui/myArts/myArtsContainer";
import NovedadesSearchContainer from "../ui/novedades/novedadesSearchContainer";
import ProviderContainer from "../ui/providers/providerContainer";
import StatisticContainer from "../ui/statistic/statisticContainer";
import TutorialsContainer from "../ui/tutorials/tutorialsContainer";
import UsersContainer from "../ui/users/usersContainer";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: "65px",
    overflow: "auto",
    width: theme.spacing(1),
  },
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: "#f5f6fa",
  },
}));

const AuthRoutes = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const providers = useSelector((state) => state.providers);
  const drawerStatus = useSelector((state) => state.userPreferences.drawer);
  const roles = useSelector((state) => state.auth.roles);
  const art = useSelector((state) => state.art);
  const [mobileOpen, setMobileOpen] = useState(drawerStatus);

  useEffect(() => {
    if (roles && roles.PRESTADOR) dispatch(getUserProviders());
  }, [roles]);

  const getDrawerNotifications = () => {
    dispatch(getAnsweredAuthorizationsNotification());
    dispatch(getConnectivityErrorsNotProcessedCount());
  };

  useEffect(() => {
    let interval;
    if (roles.PRESTADOR) {
      getDrawerNotifications();
      interval = setInterval(getDrawerNotifications, 7200000);
    }

    return () => {
      if (roles.PRESTADOR) {
        clearInterval(interval);
      }
    };
  }, [providers.selectedProvider]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
    dispatch(changeDrawerStatus(!mobileOpen));
  }, [mobileOpen]);

  if (providers.didInvalidate && providers.list.length === 0) return <Error />;

  if (!roles.SOPORTE && (providers.isFetching || art.isFetching))
    return <LoadingComponent />;


  if (roles && roles.SISTEMA) {
    return (
      <Routes>
        <Route path="/attendance" element={<AuthorizedAttendanceContainer />} />
        <Route path="*" element={<Navigate to="/attendance" />} />
      </Routes>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
      <Drawer open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <main className={classes.content}>
        {roles && roles.PRESTADOR && (
          <Routes>
            <Route path="/home" element={<DashboardContainer />} />
            <Route path="/accidents/current" element={<Accident />} />
            <Route path="/accidents/search" element={<SearchContainer />} />
            <Route path="/accidents/new" element={<NewAccidentsContainer />} />
            <Route
              path="/accidents/importIet"
              element={<ImportIetContainer />}
            />
            <Route
              path="/authorizations/pending"
              element={<PendingAuthorizationsContainer />}
            />
            <Route
              path="/authorizations/answered"
              element={<AnsweredContainer />}
            />
            <Route
              path="/authorizations/search"
              element={<AuthorizationsSearchContainer />}
            />
            <Route
              path="/appointments/pending"
              element={<PendingAppointmentsContainer />}
            />
            <Route
              path="/appointments/search"
              element={<AppointmentsSearchContainer />}
            />
            <Route path="/appointments/QR" element={<QRContainer />} />
            <Route path="/myWris" element={<MyArtsContainer />} />
            <Route path="/statistic" element={<StatisticContainer />} />
            <Route path="/tutorials" element={<TutorialsContainer />} />
            <Route path="/emails" element={<EmailContainer />} />
            <Route
              path="/respuestasConectividad/errors"
              element={<ConnectivityErrorsContainer />}
            />
            <Route
              path="/respuestasConectividad/search"
              element={<SearchConnectivityErrorsContainer />}
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        )}
        {roles && roles.ADMIN && roles.tipo === "Administrador" && (
          <Routes>
            <Route path="/configuration/users" element={<UsersContainer />} />
            <Route path="/configuration/arts" element={<DashboardCrudArt />} />
            <Route
              path="/configuration/providers"
              element={<ProviderContainer />}
            />
            <Route path="/configuration/files" element={<FilesContainer />} />
            <Route path="*" element={<Navigate to="/configuration/users" />} />
          </Routes>
        )}
        {roles && roles.SOPORTE && roles.tipo === "Soporte" && (
          <Routes>
            <Route path="/configuration/users" element={<UsersContainer />} />
            <Route path="*" element={<Navigate to="/configuration/users" />} />
            <Route path="/accidents/search" element={<ArtSearchContainer />} />
            <Route
              path="/novedades/search"
              element={<NovedadesSearchContainer />}
            />
            <Route
              path="/turnos/search"
              element={<SupportAppointmentsContainer />}
            />
          </Routes>
        )}
        {roles && roles.ART && (
          <Routes>
            <Route path="/art/prestadores" element={<PrestadorContainer />} />
            <Route
              path="/art/accidents/search"
              element={<ArtSearchContainer />}
            />
            <Route path="*" element={<Navigate to="/art/prestadores" />} />
          </Routes>
        )}
      </main>
    </div>
  );
};

export default AuthRoutes;
