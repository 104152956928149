import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Clear } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: 15,
  },
  card: {
    display: "flex",
    marginTop: 10,
    marginBottom: 5,
  },
  infoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    marginLeft: 8,
    paddingLeft: 15,
  },
  prestacionesContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },
  prestacionesSolicitadasContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#f7f7f7",
    border: "solid",
    borderWidth: "thin",
    borderColor: "lightGrey",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
  },
  button: {
    cursor: "pointer",
    marginRight: 15,
  },
  disabledButton: {
    marginRight: 15,
    color: "grey",
  },
}));

const PrestacionesCard = ({ prestaciones, disabled, handleDelete }) => {
  const classes = useStyles();

  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const affectedZonesState = useSelector((state) => state.forms.affectedZones);

  const getPrestacionNombre = (prestacion) => {
    const p = prestacionesState.data.find((p) => p.id === prestacion.id);
    return p ? p.nombre : undefined;
  };

  const getZonaAfectadaNombre = (prestacion) => {
    const za = affectedZonesState.data.find(
      (z) => z.id === prestacion.zonaAfectada,
    );
    return za ? za.nombre : undefined;
  };

  return (
    <div className={classes.container}>
      {prestaciones.map((prestacion) => (
        <div
          className={classes.card}
          key={prestacion.id + prestacion.zonaAfectada}>
          <Paper elevation={2} className={classes.infoContainer}>
            <div className={classes.prestacionesContainer}>
              <div>
                <Typography>{getPrestacionNombre(prestacion)}</Typography>
                <Typography>{getZonaAfectadaNombre(prestacion)}</Typography>
              </div>
              <Typography>{prestacion.cantidad}</Typography>
            </div>
            {prestacion.estado && prestacion.estado !== "Pendiente" && (
              <div className={classes.prestacionesSolicitadasContainer}>
                <div className={classes.rowContainer}>
                  <Typography>{prestacion.estado}</Typography>
                  <Typography>
                    {prestacion.fechaAutorizacionArt
                      ? moment(prestacion.fechaAutorizacionArt).format(
                          "DD/MM/YYYY",
                        )
                      : ""}
                  </Typography>
                  <Typography>{prestacion.codigoAutorizacionArt}</Typography>
                </div>
                <Typography>{prestacion.cantidadAutorizada}</Typography>
              </div>
            )}
          </Paper>
          <Paper elevation={2} className={classes.actionContainer}>
            <Tooltip
              title="Eliminar"
              className={
                disabled ||
                (prestacion.estado && prestacion.estado !== "Pendiente")
                  ? classes.disabledButton
                  : classes.button
              }
              onClick={() => {
                if (
                  !disabled &&
                  (!prestacion.estado || prestacion.estado === "Pendiente")
                ) {
                  handleDelete(prestacion);
                }
              }}>
              <Clear />
            </Tooltip>
          </Paper>
        </div>
      ))}
    </div>
  );
};

export default PrestacionesCard;
