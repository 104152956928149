import { makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
  },
  body: {
    marginTop: 30,
  },
}));

const AppointmentSessionHeader = ({ appointment, siniestro, trabajador }) => {
  const classes = useStyles();

  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );

  const getEspecialidad = (appointment) => {
    const especialidad = professionalSpecialties.data.find(
      (s) => s.id === appointment.especialidad,
    );
    return especialidad ? especialidad.nombre : "";
  };

  return (
    <Paper elevation={2} className={classes.container}>
      <Typography variant="body2">
        <b>
          Los Turnos ingresados a continuación corresponden al Siniestro{" "}
          {siniestro} {trabajador}.
        </b>
      </Typography>
      <Typography variant="body2">
        <b>
          Para el Profesional {appointment.profesionalApellidoNombre}{" "}
          {getEspecialidad(appointment)}.
        </b>
      </Typography>

      <Typography variant="body2" className={classes.body}>
        <b>
          Usted puede modificar individualmente la Hora de Citación de cada uno
          de ellos y si corresponde coordinar traslados.
        </b>
      </Typography>
    </Paper>
  );
};

export default AppointmentSessionHeader;
