import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Close, Edit } from "@mui/icons-material";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 10,
    padding: 10,
    display: "flex",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    margin: 5,
    cursor: "pointer",
  },
}));

const ErroresConectividadCardDetail = ({
  error,
  handleOpenDetail,
  selectedDocument,
  desestimarErrorConectividad,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div>
        <Typography>
          {error.fechaHoraProcesada
            ? moment(error.fechaHoraProcesada).format("DD/MM/YYYY")
            : ""}
        </Typography>
        <Typography>
          <strong>Observación:</strong> {error.observaciones}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>
        <Tooltip arrow title="Editar">
          <Edit
            className={classes.icon}
            onClick={() => handleOpenDetail(selectedDocument, error)}
          />
        </Tooltip>
        <Tooltip arrow title="Desestimar">
          <Close
            sclassName={classes.icon}
            onClick={() => desestimarErrorConectividad(error)}
          />
        </Tooltip>
      </div>
    </Paper>
  );
};

export default ErroresConectividadCardDetail;
