import axios from "axios";

export const GET_FORM_MODEL = "GET_FORM_MODEL";
export const CLEAR_FORM = "CLEAR_FORM";
export const GET_FORM_DIAGNOSIS = "GET_FORM_DIAGNOSIS";
export const GET_FORM_NATURALEZAS_LESION = "GET_FORM_NATURALEZAS_LESION";
export const GET_FORM_ZONAS_AFECTADAS = "GET_FORM_ZONAS_AFECTADAS";
export const GET_FORM_PROFESSIONALS = "GET_FORM_PROFESSIONALS";
export const SET_FORM_PROFESSIONALS = "SET_FORM_PROFESSIONALS";
export const UPDATE_FORM_PROFESSIONALS = "UPDATE_FORM_PROFESSIONALS";
export const CLEAR_FORM_PROFESSIONALS = "CLEAR_FORM_PROFESSIONALS";
export const GET_FORM_PROFESSIONAL_SPECIALTIES = "GET_PROFESSIONAL_SPECIALTIES";
export const CLEAR_FORM_DIAGNOSIS = "CLEAR_FORM_DIAGNOSIS";
export const POST_FORM = "POST_FORM";
export const GET_FORM = "GET_FORM";
export const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
export const GET_ATTACHMENT_BASE64 = "GET_ATTACHMENT_BASE64";
export const GET_FORM_PRESTACIONES = "GET_FORM_PRESTACIONES";
export const GET_FORM_EMPLOYERS = "GET_FORM_EMPLOYERS";
export const GET_FORM_SPECIALTIES_DIAGNOSIS = "GET_FORM_SPECIALTIES_DIAGNOSIS";
export const POST_SIGN_FORM = "POST_SIGN_FORM";
export const CLEAR_IDTURNOS = "CLEAR_IDTURNOS";
export const SET_CHANGE_DOCUMENT = "SET_CHANGE_DOCUMENT";
export const SET_CHANGE_OPEN_DOCUMENT_DETAIL =
  "SET_CHANGE_OPEN_DOCUMENT_DETAIL";
export const GET_AFFECTED_ZONES = "GET_AFFECTED_ZONES";
export const CLEAR_AFFECTED_ZONES = "CLEAR_AFFECTED_ZONES";
export const GET_LOCALIDADES_PROVINCIA = "GET_LOCALIDADES_PROVINCIA";
export const CLEAR_FORM_LOCALIDADES = "CLEAR_FORM_LOCALIDADES";

export const getFormModel = (type, idArt, idSiniestro) => (dispatch) => {
  return dispatch({
    type: GET_FORM_MODEL,
    payload: axios
      .get(`form/${type}/model?artId=${idArt}&siniestro=${idSiniestro}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const clearForm = () => ({
  type: CLEAR_FORM,
});

export const clearIdTurnosForms = () => ({
  type: CLEAR_IDTURNOS,
});

export const clearAffectedZones = () => ({
  type: CLEAR_AFFECTED_ZONES,
});

export const getFormNaturalezasLesion = (wriId) => (dispatch) => {
  return dispatch({
    type: GET_FORM_NATURALEZAS_LESION,
    payload: axios
      .get(`form/naturalezasLesion?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormZonasAfectadas = (wriId) => (dispatch) => {
  return dispatch({
    type: GET_FORM_ZONAS_AFECTADAS,
    payload: axios
      .get(`form/zonasAfectadas?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getAffectedZones = (prestacionId) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  const { accident } = getState().accidentDashboard;

  return dispatch({
    type: GET_AFFECTED_ZONES,
    payload: axios
      .get(
        `form/affectedZones?prestacionId=${prestacionId}&providerId=${selectedProvider}&wriId=${accident.mlCloudCoreArtId}`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormDiagnosis = (wriId) => (dispatch) => {
  dispatch({
    type: GET_FORM_DIAGNOSIS,
    payload: axios
      .get(`form/diagnosis?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormProfessionals = (wriId) => (dispatch) => {
  dispatch({
    type: GET_FORM_PROFESSIONALS,
    payload: axios
      .get(`form/professionals?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const setFormProfessionals = (newProfessional) => (dispatch) => {
  dispatch({
    type: SET_FORM_PROFESSIONALS,
    payload: newProfessional,
  });
};

export const updateFormProfessionals = (professional) => (dispatch) => {
  dispatch({
    type: UPDATE_FORM_PROFESSIONALS,
    payload: professional,
  });
};

export const getFormProfessionalSpecialties = (wriId) => (dispatch) => {
  dispatch({
    type: GET_FORM_PROFESSIONAL_SPECIALTIES,
    payload: axios
      .get(`form/professionalSpecialties?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postForm = (type, form, idArt) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  const { accident } = getState().accidentDashboard;
  if (idArt) {
    return dispatch({
      type: POST_FORM,
      payload: axios
        .post(
          `form/${type}?idArt=${idArt}&providerId=${selectedProvider}`,
          form,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  } else {
    return dispatch({
      type: POST_FORM,
      payload: axios
        .post(
          `form/${type}?idArt=${accident.mlCloudCoreArtId}&providerId=${selectedProvider}`,
          form,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getForm =
  (type, wriId, siniestro, nroFormulario) => (dispatch) => {
    return dispatch({
      type: GET_FORM,
      payload: axios
        .get(
          `form/${type}?idArt=${wriId}&siniestro=${siniestro}&nroFormulario=${nroFormulario}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const downloadAttachment =
  (nroFormulario, filename, idSiniestro, idArt) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;

    return dispatch({
      type: DOWNLOAD_ATTACHMENT,
      payload: axios
        .get(
          `form/attachment/download?idArt=${idArt ? idArt : accident.mlCloudCoreArtId}&siniestro=${idSiniestro ? idSiniestro : accident.idSiniestro}&nroFormulario=${nroFormulario}&filename=${filename}`,
          {
            responseType: "blob",
          },
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAttachmentBase64 =
  (nroFormulario, filename, idSiniestro, idArt) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;

    dispatch({
      type: GET_ATTACHMENT_BASE64,
      payload: axios
        .get(
          `form/attachment/base64?idArt=${idArt ? idArt : accident.mlCloudCoreArtId}&siniestro=${idSiniestro ? idSiniestro : accident.idSiniestro}&nroFormulario=${nroFormulario}&filename=${filename}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getFormPrestaciones =
  (idART, isSupport) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;
    const { selectedProvider } = getState().providers;

    if (idART) {
      dispatch({
        type: GET_FORM_PRESTACIONES,
        payload: axios
          .get(
            `form/prestaciones?wriId=${idART}&ProviderId=${selectedProvider}&isSupport=${isSupport ?? false}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    } else {
      dispatch({
        type: GET_FORM_PRESTACIONES,
        payload: axios
          .get(
            `form/prestaciones?wriId=${accident.mlCloudCoreArtId}&ProviderId=${selectedProvider}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const getFormEmployers = (wriId) => (dispatch) => {
  dispatch({
    type: GET_FORM_EMPLOYERS,
    payload: axios
      .get(`form/employers?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormSpecialtiesDiagnosis = (wriId) => (dispatch) => {
  dispatch({
    type: GET_FORM_SPECIALTIES_DIAGNOSIS,
    payload: axios
      .get(`form/specialtiesDiagnosis?wriId=${wriId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postSignForm = (form) => (dispatch, getState) => {
  const { accident } = getState().accidentDashboard;

  return dispatch({
    type: POST_SIGN_FORM,
    payload: axios
      .post(`form/sign?idArt=${accident.mlCloudCoreArtId}`, form)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const setChangeDocument = (bool) => (dispatch) => {
  dispatch({
    type: SET_CHANGE_DOCUMENT,
    payload: bool,
  });
};

export const setOpenAccidentDocumentsDetail = (bool) => (dispatch) => {
  dispatch({
    type: SET_CHANGE_OPEN_DOCUMENT_DETAIL,
    payload: bool,
  });
};

export const getLocalidades =
  (idArt, provincia, isDesde) => (dispatch, getState) => {
    const aux = isDesde ? "desde" : "hasta";
    return dispatch({
      type: GET_LOCALIDADES_PROVINCIA,
      payload: axios
        .get(`form/localidades?idArt=${idArt}&provincia=${provincia}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
      // Variable de meta se usa para el fetch/caso de error para saber donde informacion
      meta: { donde: aux },
    });
  };

export const clearFormLocalidades = () => (dispatch) => {
  return dispatch({
    type: CLEAR_FORM_LOCALIDADES,
  });
};
