import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  getPrePrintedForms,
  openAppointmentsGrid,
} from "../../../../actions/accidentDashboard";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import { openNewForm } from "../../../../actions/formsState";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import useFormCalendar from "../../../commons/hooks/useFormCalendar";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Iami from "./iami";

const initialIamiModel = {
  nombreTipoFormulario: null,
  tipoSiniestro: "",
  fechaHoraAccidente: null,
  fechaHoraAbondonoTrabajo: null,
  fechaPrimeraCuracion: null,
  fechaHoraProximaConsulta: null,
  reagravacion: false,
  tipoReingreso: null,
  fechaReagravacion: null,
  fechaAtencionReagravacion: null,
  comoOcurrioAccidente: "",
  diagnosticoOMS: null,
  diagnostico: "",
  formaAccidente: null,
  agenteCausante: null,
  naturalezaLesion: null,
  zonaAfectada: null,
  gravedad: "",
  atencionMedicaAnterior: false,
  centroAsistencial: "",
  tratamiento: "",
  bajaLaboral: false,
  diasEstimados: "",
  sugerenciaRechazo: false,
  causaRechazo: null,
  suponeIncapacidad: false,
  manoHabil: null,
  r79: false,
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const appointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  fromDB: false,
};

/**
 * @param {function} handleClose Funcion a llamar para cerrar el componente que contiene al form.
 * @param {bool} isView Marca si el documento ya existe y esta siendo editado o si es uno nuevo.
 * @param {function} setLoaded Funcion para marcar cuando el componente termino de cargar sus requisitos.
 * @param {object} selectedDocument documento seleccionado actualmente (ver si cambiar el nombre/borrar este parametro).
 * @param {number} idArt id del prestador.
 * @param {boolean} disabled Si este valor es null se utiliza el valor de roles que viene de backend.
 * @param {function} postFormCallback Callback a ejecutarse si el form se envio correctamente.
 * @returns
 */

const IamiContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  const calendar = useFormCalendar();
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const [iamiModel, setIamiModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [appointment, setAppointment] = useState(appointmentModel);
  const [openAppointment, setOpenAppointment] = useState(false);

  const disabledForm =
    disabled !== null
      ? disabled
      : form.data.roles
        ? !form.data.roles?.editar
        : true;

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    professionals.isFetching ||
    (isView && calendar.isFetching) ||
    !iamiModel ||
    !formValues;

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      if (form.data.form.turno) {
        setAppointment(form.data.form.turno);
      } else {
        form.data.form.turno = appointmentModel;
        setAppointment(appointmentModel);
      }
    }
  }, [form.isFetching]);

  // Configurar valores del form iniciales: (tanto al editar como abrir uno nuevo)
  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setIamiModel(model.data);
        setFormValues((prevState) => ({
          ...initialIamiModel,
          fechaPrimeraCuracion: new Date(),
          fechaHoraAccidente: model.data.fields.fechaHoraAccidente.options
            ? new Date(model.data.fields.fechaHoraAccidente.options)
            : initialIamiModel.fechaHoraAccidente,
          fechaReagravacion: model.data.fields.fechaReagravacion.options
            ? new Date(model.data.fields.fechaReagravacion.options)
            : initialIamiModel.fechaReagravacion,
          reagravacion: model.data.fields.fechaReagravacion.options
            ? true
            : initialIamiModel.reagravacion,
          gravedad: model.data.fields.gravedad.options.includes("Leve")
            ? "Leve"
            : "",
          naturalezaLesion: model.data.fields.naturalezaLesion
            ? model.data.fields.naturalezaLesion.options
            : prevState.naturalezaLesion,
          zonaAfectada: model.data.fields.zonaAfectada
            ? model.data.fields.zonaAfectada.options
            : prevState.zonaAfectada,
          diagnosticoOMS: model.data.fields.diagnosticoOMS
            ? model.data.fields.diagnosticoOMS.options
            : prevState.diagnosticoOMS,
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialIamiModel.profesionalApellidoNombre,
          nombreTipoFormulario:
            model.data.fields.tiposFormulario.options.length === 1
              ? model.data.fields.tiposFormulario.options[0].nombre
              : initialIamiModel.nombreTipoFormulario,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialIamiModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialIamiModel.informacionSensible,
          fechaHoraAbondonoTrabajo: model.data.fields?.fechaHoraAbondonoTrabajo
            .options
            ? model.data.fields?.fechaHoraAbondonoTrabajo.options
            : initialIamiModel.fechaHoraAbondonoTrabajo,
          tipoSiniestro: model.data.fields?.tipoSiniestro.options.selected
            ? model.data.fields?.tipoSiniestro.options.selected
            : initialIamiModel.tipoSiniestro,
        }));
      }
    } else if (!form.isFetching) {
      setIamiModel(form.data.model);
      setFormValues(form.data.form);
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);
  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.calendar?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          (d.estado === "Programados" || d.estado === "No informados"),
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const body = {
        ...form,
        numeroSiniestroPrestador: idSiniestro,
        adjuntos: filesAux,
        turno: null,
        fechaHoraAccidente: convertDateTimeToNet(form.fechaHoraAccidente),
        fechaHoraAbondonoTrabajo: convertDateTimeToNet(
          form.fechaHoraAbondonoTrabajo,
        ),
        fechaPrimeraCuracion: convertDateTimeToNet(form.fechaPrimeraCuracion),
        fechaHoraProximaConsulta: convertDateTimeToNet(
          form.fechaHoraProximaConsulta,
        ),
        fechaReagravacion: convertDateTimeToNet(form.fechaReagravacion),
        fechaAtencionReagravacion: convertDateTimeToNet(
          form.fechaAtencionReagravacion,
        ),
      };

      if (appointment.fechaHora !== null) {
        body.turno = {
          ...appointment,
          fechaHora: convertDateTimeToNet(appointment.fechaHora),
        };
      }
      dispatch(postForm("iami", body, idArt))
        .then((res) => {
          appointment.idTurnoSiniestro = res.value?.data?.idTurnosArray?.[0];
          dispatch(getFormProfessionals(idArt));
          dispatch(getPrePrintedForms(idArt, idSiniestro));
        })
        .then(() => {
          if (postFormCallback) {
            postFormCallback(form);
          }
          handleClose(true, false);
        })
        .catch((e) => {
          handleClose(true, true);
        })
        .finally(() => {
          // Este codigo es para abrir fpt si se hizo un turno nuevo con traslado
          const fptDoc = documentation.data.find((f) => f.formulario === "Fpt");
          if (
            appointment.traslado &&
              appointment.fechaHora >= new Date() &&
              !appointment.fromDB &&
              fptDoc
              ? true
              : false
          ) {
            const especialidad = professionalSpecialties.data.find(
              (e) => e.id == appointment.especialidad,
            );

            var appointmentAux = {
              professionalName: appointment.profesionalApellidoNombre,
              specialityName: especialidad.nombre,
              appointmentDates: [appointment.fechaHora],
              appointmentsIds: [appointment.idTurnoSiniestro],
            };
            dispatch(
              openNewForm(
                "Fpt",
                "Formulario de Pedido de Traslado",
                appointmentAux,
              ),
            );
          }
          dispatch(clearAppointmentAssist());
        });
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  const handleAppointmentSubmit = (appointment) => {
    setAppointment(appointment);
    setOpenAppointment(false);
  };

  const closeIami = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Iami
          handleClose={closeIami}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={iamiModel}
          formValues={formValues}
          selectedDocument={selectedDocument}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => setOpenAppointment(false)}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          isView={isView}
          disabled={disabledForm}
          idArt={idArt}
        />
      )}
    </div>
  );
};

export default IamiContainer;
