import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import { postForm } from "../../../../actions/forms";
import { openNewForm, storeFormValues } from "../../../../actions/formsState";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import { formBehaviourEnum } from "../../../commons/enum/formEnums";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Fpa from "./fpa";
import FpaAutorizationDialog from "./fpaAutorizationDialog";
import FpaConfirmDialog from "./fpaConfirmDialog";

let idTurnosArray = [];

const initialFpaModel = {
  nombreTipoFormulario: null,
  fechaHoraPedido: null,
  enInstitucionPropia: true,
  diagnostico: "",
  detalleAutorizacion: "",
  planTerapeutico: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
  turnos: [],
  turnosADesvincular: [],
};

const FpaContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
  trabajador,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const professionals = useSelector((state) => state.forms.professionals);
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const fpa = useSelector((state) => state.formsState.fpa);
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const [fpaModel, setFpaModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [prestaciones, setPrestaciones] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(fpa.openConfirmDialog ?? false);
  const [openAutorizationDialog, setOpenAutorizationDialog] = useState(false);
  const [codigoAutorizacion, setCodigoAutorizacion] = useState(null);
  const [formPostValues, setFormPostValues] = useState({});
  const [loading, setLoading] = useState(false);

  const disabledForm =
    disabled !== null
      ? disabled
      : form.data.roles
        ? !form.data.roles?.editar
        : true;
  const isLoading =
    loading ||
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    professionals.isFetching ||
    !fpaModel ||
    !formValues ||
    (isView && !prestacionesState.data);


  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      setPrestaciones(form.data.form.prestaciones);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setFpaModel(model.data);
        setFormValues({
          ...initialFpaModel,
          diagnostico: model.data.fields.diagnostico.options
            ? model.data.fields.diagnostico.options
            : initialFpaModel.diagnostico,
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialFpaModel.profesionalApellidoNombre,
          nombreTipoFormulario:
            model.data.fields.tiposFormulario.options.length === 1
              ? model.data.fields.tiposFormulario.options[0].nombre
              : initialFpaModel.nombreTipoFormulario,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialFpaModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialFpaModel.informacionSensible,
          // Estas variables se usan solo al reabrir el form desde un fpt. Ya que ignoran los valores por defecto del form
          ...fpa.formProps,
        });
      }
    } else if (!form.isFetching) {
      setFpaModel(form.data.model);
      setFormValues({ ...form.data.form, turnosADesvincular: [] });
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  const handleCloseAutorizationDialog = (form) => {
    setOpenAutorizationDialog(false);
    setOpenConfirmDialog(true);
    handleOpenFpt();
  };

  const formValuesOtherFpa = (formValues) => {
    return {
      ...formValues,
      detalleAutorizacion: "",
      turnos: [],
      turnosADesvincular: [],
    }
  }

  const handleOpenOtherFpa = () => {
    // Si el usuario hace click en "Guardar y Continuar", pero luego en el
    // segundo FPA cancela la operación, deberia ver el primer formulario
    // cargado en la lista de documentos.
    dispatch(getDocuments(idSiniestro, idArt));
    setPrestaciones([]);
    setFormValues(formValuesOtherFpa(formPostValues));
    setLoading(false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleConfirmAndClose = () => {
    handleClose(false, false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleOpenFpt = (form) => {
    // Guardado de datos del fpa para volver a abrirlo
    const fptDoc = documentation.data.find((f) => f.formulario === "Fpt")
    if (!isView) {
      dispatch(storeFormValues("fpa", { openConfirmDialog: true, formProps: formValuesOtherFpa(form ? form : formPostValues) }));
    }
    const turnos = form ? form.turnos : formPostValues.turnos;
    if (
      turnos && turnos.length > 0 &&
      idTurnosArray.length > 0 &&
      new Date(turnos[0].fechaHora) >= new Date() &&
      !turnos[0].fromDB &&
      !!fptDoc
    ) {
      const especialidad = professionalSpecialties.data.find(
        (e) => e.id == turnos[0].especialidad,
      );
      var appointmentAux = {
        professionalName: turnos[0].profesionalApellidoNombre,
        specialityName: especialidad.nombre,
        appointmentDates: turnos.map((t) => t.fechaHora),
        appointmentsIds: idTurnosArray,
      };
      if (!isView) {
        // Tiene el comportaminento que lo hace volver a fpa una vez completado
        dispatch(openNewForm("Fpt", "Formulario de Pedido de Traslado", appointmentAux, formBehaviourEnum.RETURN_TO_FPA));
      } else {
        dispatch(openNewForm("Fpt", "Formulario de Pedido de Traslado", appointmentAux));
      }
    }
  }

  const handleSubmit = (form) => {

    const filesAux = [];
    let autorizado = false;
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    setFormPostValues(form);
    const model = {
      ...form,
      numeroSiniestroPrestador: idSiniestro,
      adjuntos: filesAux,
      prestaciones: prestaciones,
      fechaHoraPedido: convertDateTimeToNet(form.fechaHoraPedido),
      turnos: form?.turnos?.map((t) => ({ ...t, fechaHora: convertDateTimeToNet(t.fechaHora) })) ?? [],

    };
    setLoading(true);
    dispatch(postForm("fpa", model, idArt))
      .then((res) => {
        idTurnosArray = res.value?.data?.idTurnosArray || [];
        if (!isView) {
          if (res.value.data.codigoAutorizacion !== "0") {
            setOpenAutorizationDialog(true);
            setCodigoAutorizacion(res.value.data.codigoAutorizacion);
            // Necesito esta variable que es lo mismo que openAutorization en este ciclo, al contrario de autorization  dialog que se actualizare en el siguiente render.
            autorizado = true;
          } else {
            setOpenConfirmDialog(true);
          }
        } else {
          handleClose(true, false);
        }
      })
      .then(() => {
        if (postFormCallback) {
          postFormCallback(form);
        }
      })
      .catch((e) => {
        handleClose(true, true);
      })
      .finally(() => {
        if (!autorizado) {
          handleOpenFpt(form);
        }

      });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const closeFpa = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Fpa
          handleClose={closeFpa}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={fpaModel}
          formValues={formValues}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          prestaciones={prestaciones}
          setPrestaciones={setPrestaciones}
          isView={isView}
          disabled={disabledForm}
          idArt={idArt}
          idSiniestro={idSiniestro}
          trabajador={trabajador}
        />
      )}
      <FpaConfirmDialog
        open={openConfirmDialog}
        title="CONFIRMAR FORMULARIO"
        text="¿Desea cargar un nuevo pedido de autorización?"
        handleConfirmarYFinalizar={handleConfirmAndClose}
        handleConfirmarYContinuar={handleOpenOtherFpa}
      />
      <FpaAutorizationDialog
        open={openAutorizationDialog}
        title="PEDIDO DE AUTORIZACION"
        handleSubmit={handleCloseAutorizationDialog}
        codigoAutorizacion={codigoAutorizacion}
      />
    </div>
  );
};

export default FpaContainer;
