import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissConnectivityErrorAction,
  getAllConnectivityErrors,
  setTime,
} from "../../../../actions/connectivityResponse";
import { openEditForm, setFormsGeneralProperties } from "../../../../actions/formsState";
import snackbarUtils from "../../../../utils/snackbarUtils";
import { formBehaviourEnum } from "../../../commons/enum/formEnums";
import FormContainer from "../../forms/formContainer";
import ErroresConectividadTable from "./ConnectivityErrorsTable";

const ConnectivityErrorsContainer = () => {
  const dispatch = useDispatch();

  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const namesArts = useSelector((state) => state.art.shortNameArt);
  const erroresConectividad = useSelector(
    (state) => state.connectivityResponse.allConnectivityErrors,
  );

  useEffect(() => {
    dispatch(getAllConnectivityErrors(time));
  }, []);

  const handleChange = (event) => {
    dispatch(setTime(event.target.value));
    dispatch(getAllConnectivityErrors(event.target.value));
  };
  const handleDesestimar = (rowData) => {
    dispatch(
      dismissConnectivityErrorAction(
        rowData.idRespError,
        rowData.mlCloudCoreArtId,
      ),
    ).then(() => {
      dispatch(getAllConnectivityErrors(time)).then(() => {
        snackbarUtils.success("Error desestimado correctamente");
      });
    });
  };

  const handleOpenEditForm = (rowData) => {

    dispatch(setFormsGeneralProperties({
      idSiniestro: rowData.siniestro,
      idArt: rowData.mlCloudCoreArtId,
      artName: rowData.art,
      documento: rowData.documento,
      trabajador: rowData.trabajador,
      numeroHistoriaClinica: rowData.historiaClinica,

    }))

    dispatch(openEditForm(rowData.comportamiento, formBehaviourEnum.ERROR_CONECTIVIDAD, {
      nroFormulario: rowData.nroFormulario,
      idRespError: rowData.idRespError,
    }, {})
    );

  };



  return (
    <div>
      <ErroresConectividadTable
        data={erroresConectividad.data?.registers || []}
        loading={erroresConectividad.isFetching}
        namesArts={namesArts}
        fetchData={() => dispatch(getAllConnectivityErrors(time))}
        title="Errores Conectividad"
        handleChangeTime={handleChange}
        handleDesestimar={handleDesestimar}
        handleOpenEditForm={handleOpenEditForm}
      />
      <FormContainer />
    </div>
  );
};

export default ConnectivityErrorsContainer;
