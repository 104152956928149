import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRespuestasNovedades } from "../../../actions/connectivityResponse";

const useStyles = makeStyles((theme) => ({
  errorCell: {
    paddingRight: 20,
  },
}));

const RespuestasNovedadesDialog = ({ respuesta, open, handleClose }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const respuestasNovedades = useSelector(
    (state) => state.connectivityResponse.searchConnectivityResponses,
  );

  const isLoading = respuestasNovedades.isFetching;

  useEffect(() => {
    if (respuesta) {
      dispatch(getRespuestasNovedades(respuesta));
    }
  }, [respuesta]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Registros de Errores</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : respuestasNovedades.data.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.errorCell}>Error</TableCell>
                  <TableCell>Detalle</TableCell>
                  <TableCell>Fecha Procesado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {respuestasNovedades.data.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log.error ? "1" : "0"}</TableCell>
                    <TableCell>{log.detalle}</TableCell>
                    <TableCell>
                      {new Date(log.fechaHoraProcesado).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No hay registros disponibles.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RespuestasNovedadesDialog;
