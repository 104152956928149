import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import Logo from "../../../../../assets/images/logo_small.png";
import FormikTextField from "../../../../commons/formik/formikTextField";
import { AttendanceValidation } from "./attendanceFormvalidations";
const Attendance = ({ initialValues, handleSubmit, deviceLocation, documentNumber, loading }) => {



    return (
        <Formik
            validationSchema={AttendanceValidation(deviceLocation)}
            onSubmit={handleSubmit}
            initialValues={initialValues}
        >
            {({ values, setFieldValue }) => (

                <Form >

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        maxWidth="false"
                        spacing={4}
                    >
                        <Grid item>
                            <img src={Logo} alt="" />
                        </Grid>
                        <Grid item>
                            <FormikTextField
                                fullWidth
                                name="documento"
                                variant="outlined"
                                label="DNI"
                                placeholder="Ingrese su DNI"
                                inputProps
                                type="number"
                                disabled={!!documentNumber}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={4}
                                        color="secondary"
                                    />
                                ) : (
                                    "Dar asistencia"
                                )}

                            </Button>
                        </Grid>
                    </Grid>

                </Form>
            )}
        </Formik>
    )
}



export default Attendance;