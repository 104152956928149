import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteArt, getArts, postArt, putArt } from "../../../actions/arts";

import ConfirmDeleteDialog from "../../commons/dialog/confirmDeleteDialog";
import EditArtDialog from "../../commons/dialog/editArtDialog";
import CrudArtTable from "../../commons/table/crudArtTable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "10px 30px",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const initialArt = {
  enviarFormularios: false,
  enviarTurnos: false,
  metodoCargaSiniestros: "",
};

const DashboardCrudArtContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const arts = useSelector((state) => state.arts.list);
  const loading = useSelector((state) => state.arts.isFetching);
  const error = useSelector((state) => state.arts.didInvalidate);
  const isLoadingEditArt = useSelector((state) => state.arts.isLoadingEditArt);
  const isLoadingDeleteArt = useSelector(
    (state) => state.arts.isLoadingDeleteArt,
  );
  const didInvalidateEditArt = useSelector(
    (state) => state.arts.didInvalidateEditArt,
  );
  if (arts.length === 0 && !loading && !error) {
    dispatch(getArts());
  }

  const [addingNewArt, setAddingNewArt] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [art, setArt] = useState(initialArt);
  const [formErrors, setFormErrors] = useState({});

  const verifyForm = () => {
    let error = false;
    let newErrors = {};
    if (!art.nombre || art.nombre === "") {
      newErrors = { ...newErrors, nombre: "Campo obligatorio." };
      error = true;
    }

    if (!art.nombreCorto || art.nombreCorto === "") {
      newErrors = { ...newErrors, nombreCorto: "Campo obligatorio." };
      error = true;
    } else if (art.nombreCorto.length > 5) {
      newErrors = { ...newErrors, nombreCorto: "No puede ser mayor a 5." };
      error = true;
    }

    if (!art.integrationUrl || art.integrationUrl === "") {
      newErrors = { ...newErrors, integrationUrl: "Campo obligatorio." };
      error = true;
    }

    if (!art.nombreCliente || art.nombreCliente === "") {
      newErrors = { ...newErrors, nombreCliente: "Campo obligatorio." };
      error = true;
    }

    if (!art.integrationApiKey || art.integrationApiKey === "") {
      newErrors = { ...newErrors, integrationApiKey: "Campo obligatorio." };
      error = true;
    }

    if (!art.codigoSrt || art.codigoSrt === "") {
      newErrors = { ...newErrors, codigoSrt: "Campo obligatorio." };
      error = true;
    }

    if (!art.metodoCargaSiniestros || art.metodoCargaSiniestros === "") {
      newErrors = { ...newErrors, metodoCargaSiniestros: "Campo obligatorio." };
      error = true;
    }

    setFormErrors(newErrors);
    return error;
  };

  useEffect(() => {
    dispatch(getArts());
  }, []);

  const editArt = (event, id) => {
    const fieldId = event.target.id ? event.target.id : id;
    setArt({
      ...art,
      [fieldId]: event.target.value || event.target.checked,
    });
    setFormErrors({ ...formErrors, [fieldId]: null });
  };

  const handleOpenModalAddingNewArt = () => {
    setAddingNewArt(true);
    setOpenEditar(true);
  };

  const handleOpenModalEditar = (rowData) => {
    setArt(rowData);
    setOpenEditar(true);
  };

  const handleOpenModalEliminar = (rowData) => {
    setArt(rowData);
    setOpenEliminar(true);
  };

  const handleCloseEditar = () => {
    setArt(initialArt);
    setOpenEditar(false);
    setAddingNewArt(false);
    setFormErrors({});
  };

  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };

  const handleConfirmEditar = () => {
    if (!verifyForm()) {
      if (addingNewArt) {
        dispatch(postArt(art));
      } else {
        dispatch(putArt(art));
      }
      setFormErrors({});
    }
  };

  const handleConfirmEliminar = () => {
    dispatch(deleteArt(art.id));
    handleCloseEliminar();
    setArt(initialArt);
  };

  const fetchArts = () => {
    dispatch(getArts());
  };

  useEffect(() => {
    if (!didInvalidateEditArt) {
      handleCloseEditar();
    }
  }, [didInvalidateEditArt]);

  useEffect(() => {
    if (didInvalidateEditArt || isLoadingEditArt) {
      setOpenEditar(true);
    } else if (!isLoadingEditArt) {
      setOpenEditar(false);
      setAddingNewArt(false);
      setArt(initialArt);
    }
  }, [isLoadingEditArt]);

  useEffect(() => {
    setArt(initialArt);
  }, [arts]);

  return (
    <React.Fragment>
      <CrudArtTable
        tableIcons={tableIcons}
        arts={arts}
        loading={loading || isLoadingEditArt || isLoadingDeleteArt}
        fetchArts={fetchArts}
        handleOpenModalEditar={handleOpenModalEditar}
        handleOpenModalEliminar={handleOpenModalEliminar}
      />

      <div className={classes.root}>
        <Fab
          onClick={handleOpenModalAddingNewArt}
          disabled={loading}
          color="primary"
          aria-label="add">
          <AddIcon />
        </Fab>
      </div>

      <EditArtDialog
        art={art}
        openEditar={openEditar}
        handleCloseEditar={handleCloseEditar}
        addingNewArt={addingNewArt}
        editArt={editArt}
        handleConfirmEditar={handleConfirmEditar}
        isLoadingEditArt={isLoadingEditArt}
        didInvalidateEditArt={didInvalidateEditArt}
        formErrors={formErrors}
      />

      <ConfirmDeleteDialog
        open={openEliminar}
        handleClose={handleCloseEliminar}
        handleConfirm={handleConfirmEliminar}
        title="Eliminar ART"
        text={`¿Está seguro que desea eliminar la ART ${art.nombre}?`}
      />
    </React.Fragment>
  );
};

export default DashboardCrudArtContainer;
