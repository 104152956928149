import { useSelector } from "react-redux";
import { formBehaviourEnum } from "../enum/formEnums";

// Solo para uso dentro de componente de forms. 
// Selecciona el calendario correspondiente segun el behaviour.
const useFormCalendar = () => {
  const formsState = useSelector((state) => state.formsState);
  const selectedProviderCalendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const otherProviderCalendar = useSelector((state) => state.accidentDashboard.fromAnotherProviderAppointments)

  return formsState.behaviour === formBehaviourEnum.ATTENTION_IN_OTHER_PROVIDER ? otherProviderCalendar : selectedProviderCalendar;
}

export default useFormCalendar;