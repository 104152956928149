import axios from "axios";

export const SET_SELECTED_ACCIDENT = "SET_SELECTED_ACCIDENT";
export const GET_AVAILABLE_DOCUMENTS_TO_ADD = "GET_AVAILABLE_DOCUMENTS_TO_ADD";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_PRE_PRINTED_FORMS = "GET_PRE_PRINTED_FORMS";
export const GET_ATTENTIONS_IN_ANOTHER_PROVIDER =
  "GET_ATTENTIONS_IN_ANOTHER_PROVIDER";
export const GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML =
  "GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML";
export const GET_APPOINTMENTS_CALENDAR = "GET_APPOINTMENTS_CALENDAR";
export const CLEAR_ACCIDENT_DASHBOARD = "CLEAR_ACCIDENT_DASHBOARD";
export const GET_PRE_PRINTED_HTML = "GET_PRE_PRINTED_HTML";
export const GET_CLINIC_HISTORY_HTML = "GET_CLINIC_HISTORY_HTML";
export const OPEN_APPOINTMENTS_GRID = "OPEN_APPOINTMENTS_GRID";
export const CLOSE_APPOINTMENTS_GRID = "CLOSE_APPOINTMENTS_GRID";
export const APPOINTMENT_ASSIST = "APPOINTMENT_ASSIST";
export const CLEAR_APPOINTMENT_IN_RANGE = "CLEAR_APPOINTMENT_IN_RANGE";
export const CLEAR_APPOINTMENT_ASSIST = "CELAR_APPOINTMENT_ASSIST";

export const setSelectedAccident = (accident) => ({
  type: SET_SELECTED_ACCIDENT,
  payload: accident,
});
export const clearAppointmentAssist = () => ({
  type: CLEAR_APPOINTMENT_ASSIST,
});
export const appointmentAssist = () => ({
  type: APPOINTMENT_ASSIST,
});
export const clearAppointmentInRange = () => ({
  type: CLEAR_APPOINTMENT_IN_RANGE,
});
export const openAppointmentsGrid = (form) => ({
  type: OPEN_APPOINTMENTS_GRID,
  payload: form,
});

export const getAvailableDocumentsToAdd =
  (siniestro, WRIId, isSupport) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;
    dispatch({
      type: GET_AVAILABLE_DOCUMENTS_TO_ADD,
      payload: axios
        .get(
          `accident/availableDocumentsToAdd?AccidentId=${siniestro}&WRIId=${WRIId}&ProviderId=${selectedProvider}&Support=${isSupport ?? false
          }`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getDocuments = (siniestro, WRIId) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  dispatch({
    type: GET_DOCUMENTS,
    payload: axios
      .get(
        `accident/documents?AccidentId=${siniestro}&WRIId=${WRIId}&ProviderId=${selectedProvider}`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getPrePrintedForms = (WRIId, accidentId) => (dispatch) => {
  dispatch({
    type: GET_PRE_PRINTED_FORMS,
    payload: axios
      .get(`accident/prePrintedForms?WRIId=${WRIId}&accidentId=${accidentId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getPrePrintedHTML =
  ({ accidentId, WRIId, templateId }) =>
    (dispatch, getState) => {
      const { selectedProvider } = getState().providers;

      dispatch({
        type: GET_PRE_PRINTED_HTML,
        payload: axios
          .get(
            `accident/prePrintedHTML?accidentId=${accidentId}&WRIId=${WRIId}&providerId=${selectedProvider}&templateId=${templateId}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    };

export const getAttentionsInAnotherProvider =
  (siniestro, WRIId) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    dispatch({
      type: GET_ATTENTIONS_IN_ANOTHER_PROVIDER,
      payload: axios
        .get(
          `accident/attentionsInAnotherProvider?AccidentId=${siniestro}&WRIId=${WRIId}&ProviderId=${selectedProvider}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAttentionInAnotherProviderHTML =
  ({ accidentId, WRIId }) =>
    (dispatch, getState) => {
      const { selectedProvider } = getState().providers;

      dispatch({
        type: GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML,
        payload: axios
          .get(
            `accident/attentionInAnotherProviderHTML?AccidentId=${accidentId}&WRIId=${WRIId}&ProviderId=${selectedProvider}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    };

export const getAppointmentsCalendar =
  (siniestro, WRIId, isSupport, providerId) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;
    dispatch({
      type: GET_APPOINTMENTS_CALENDAR,
      payload: axios
        .get(
          `appointment/calendar?AccidentId=${siniestro}&WRIId=${WRIId}&ProviderId=${providerId ?? selectedProvider}&Support=${isSupport ?? false
          }`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
      // Meta es una variable extra para guardarlo en un lugar distinto si corresponde
      meta: { storeName: providerId ? "fromAnotherProviderAppointments" : "appointmentsCalendar" }
    });
  };

export const getClinicHistoryHTML =
  ({ accidentId, WRIId, formType = null }) =>
    (dispatch, getState) => {
      const { selectedProvider } = getState().providers;
      const formTypeParam = formType ?? "";
      dispatch({
        type: GET_CLINIC_HISTORY_HTML,
        payload: axios
          .get(
            `accident/clinicHistoryHTML?AccidentId=${accidentId}&WRIId=${WRIId}&ProviderId=${selectedProvider}&FormType=${formTypeParam}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    };

export const clearAccidentDashboard = () => ({
  type: CLEAR_ACCIDENT_DASHBOARD,
});
