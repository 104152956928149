import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    borderRadius: 5,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    height: "15px !important",
    width: "15px !important",
    marginRight: 5,
  },
  absentState: {
    color: "black",
  },
  notInformedState: {
    color: "red",
  },
  presentState: {
    color: "blue",
  },
  plannedState: {
    color: "green",
  },
  anulledState: {
    color: "grey",
  },
}));

const CalendarLabel = () => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.container}>
      <div className={classes.label}>
        <FiberManualRecord
          className={`${classes.icon} ${classes.notInformedState}`}
        />
        <Typography variant="caption">No Informados</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord
          className={`${classes.icon} ${classes.absentState}`}
        />
        <Typography variant="caption">Ausentes</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord
          className={`${classes.icon} ${classes.presentState}`}
        />
        <Typography variant="caption">Presentes</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord
          className={`${classes.icon} ${classes.plannedState}`}
        />
        <Typography variant="caption">Programados</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord
          className={`${classes.icon} ${classes.anulledState}`}
        />
        <Typography variant="caption">Anulados</Typography>
      </div>
    </Paper>
  );
};

export default CalendarLabel;
