import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import packagejson from "../package.json";
import App from "./App";
import UnsupportedBrowser from "./components/ui/unsupportedBrowser/unsupportedBrowser";
import UnsupportedVersionBrowser from "./components/ui/unsupportedBrowser/unsupportedVersionBrowser";

const { detect } = require("detect-browser");
const browser = detect();
const browserVersion = parseInt(browser.version);

var browserOk = true;
var friendlyBrowserName;
var browserMinVersion;

const checkUnsupportedBrowser = () => {
  if (browser.name === "ie") {
    ReactDOM.render(<UnsupportedBrowser />, document.getElementById("root"));
  } else if (browserVersion >= browserMinVersion && browserOk) {
    ReactDOM.render(<App />, document.getElementById("root"));
  } else {
    ReactDOM.render(
      <UnsupportedVersionBrowser
        browser={browser.name}
        browserName={friendlyBrowserName}
        version={browser.version}
        minVersion={browserMinVersion}
      />,
      document.getElementById("root"),
    );
  }
};

switch (browser && browser.name) {
  case "chrome":
    browserMinVersion = 57;
    friendlyBrowserName = "Google Chrome";
    break;

  case "firefox":
    browserMinVersion = 50;
    friendlyBrowserName = "Mozilla Firefox";
    break;

  case "opera":
    browserMinVersion = 50;
    friendlyBrowserName = "Opera";
    break;

  case "edge-chromium":
    browserMinVersion = 86;
    friendlyBrowserName = "Microsoft Edge (Chromium)";
    break;

  case "safari":
    friendlyBrowserName = "Safari";
    browserMinVersion = 12;
    break;

  case "samsung":
    friendlyBrowserName = "Samsung Internet";
    browserMinVersion = 8;
    break;

  case "ios":
    friendlyBrowserName = "Safari";
    browserMinVersion = 12;
    break;

  case "edge":
    friendlyBrowserName = "Microsoft Edge";
    browserOk = false;
    break;

  default:
    browserOk = false;
    break;
}

checkUnsupportedBrowser();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(CONFIG.path + "/service-worker.js")
    .then((registration) => {
      // Enviar un mensaje al Service Worker para verificar la versión
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        if (event.data.action === "updateAvailable") {
          // Activar el nuevo Service Worker
          registration.waiting.postMessage({ action: "skipWaiting" });
        }
      };
      registration.active.postMessage(
        { action: "checkVersion", version: packagejson.version },
        [messageChannel.port2],
      );
    });
}
