import {
    CLOSE_FORM,
    ERROR_LOADED_FORM,
    LOADED_FORM,
    OPEN_EDIT_FORM,
    OPEN_NEW_FORM,
    SET_FORMS_COMMON_PROPERTIES,
    SET_FORMS_DOCUMENT_LIST,
    STORE_FORM_VALUES
} from "../actions/formsState";

const INITIAL_STATE = {
    open: null,
    behaviour: null,
    loadingForm: true,
    errorLoadingForm: false,
    documentList: [], // Son objectos que contienen los mismos valores que selectedDocument
    commonProperties: {
        idSiniestro: null,
        idArt: null,
        artName: null,
        trabajador: "",
        nombreTrabajador: "",
        apellidoTrabajador: "",
        documento: null,
        numeroHistoriaClinica: null,
        idProvider: null,
    },
    selectedDocument: {
        nroFormulario: null,
        idRespError: null,
    },
    formTitle: null,
    fpt: {
        appointmentDates: [],
        appointmentsIds: [],
        specialityName: "",
        professionalName: "",
    },
    idr: {
        nroFormularioFPA: null,
    },
    iami: {

    },
    iet: {

    },
    am: {

    },
    fpa: {
        // Props de configuracion 

        // Se usa este prop para decidir si iniciarlo con el cartel de cargar otro fpa abierto o no 
        openConfirmDialog: false,

        // Props del form
        /* Estas se usan Dentro de formProps. No se les da un valor default, ya que esto entraria en conflicto con los valores default del formulario. Estos solo se usan si existen.
        nombreTipoFormulario: null,
        fechaHoraPedido: null,
        enInstitucionPropia: true,
        diagnostico: "",
        detalleAutorizacion: "",
        planTerapeutico: "",
        profesionalApellidoNombre: null,
        profesionalMnMp: "",
        especialidadProfesional: "",
        especialidadInforme: "",
        informacionSensible: false,
        */
        formProps: {}

    }

}

const formsState = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case OPEN_EDIT_FORM: {
            const { formType, formBehaviour, props, selectedDocument } = action.payload;
            return {
                ...state,
                loadingForm: true,
                errorLoadingForm: false,
                open: formType,
                behaviour: formBehaviour,
                selectedDocument: selectedDocument,
                [formType]: {
                    ...INITIAL_STATE[formType],
                    ...props,
                }
            }
        }
        case OPEN_NEW_FORM: {
            const { formType, props, formTitle, loadStoredProps, formBehaviour } = action.payload;
            return {
                ...state,
                loadingForm: true,
                errorLoadingForm: false,
                selectedDocument: null,
                open: formType,
                behaviour: formBehaviour,
                formTitle: formTitle,
                [formType]: {
                    ...INITIAL_STATE[formType],
                    ...props,
                    ...(loadStoredProps ? state[formType] : []),
                }
            }
        }
        case SET_FORMS_DOCUMENT_LIST: {
            return {
                ...state,
                documentList: action.payload.documentList,
            }
        }
        case SET_FORMS_COMMON_PROPERTIES: {
            return {
                ...state,
                commonProperties: {
                    ...INITIAL_STATE.commonProperties,
                    ...action.payload,
                }
            }
        }

        case STORE_FORM_VALUES: {
            const { formType, props } = action.payload;
            return {
                ...state,
                [formType]: {
                    ...props
                }
            }
        }

        case LOADED_FORM: {
            return {
                ...state,
                loadingForm: false,
            }
        }
        case ERROR_LOADED_FORM: {
            return {
                ...state,
                errorLoadingForm: true,
            }
        }
        case CLOSE_FORM: {
            return {
                ...INITIAL_STATE,
                documentList: state.documentList,
                commonProperties: state.commonProperties,
                fpa: state.fpa,
            };
        }


        default:
            return state;
    }
};

export default formsState;