import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { impersonateUser } from "../../../actions/login";
import {
  getListUsers,
  getUserRoles,
  userDelete,
  userRegister,
} from "../../../actions/users";
import ConfirmDeleteDialog from "../../commons/dialog/confirmDeleteDialog";
import EditUserDialog from "../../commons/dialog/editUserDialog";
import UsersTable from "../../commons/table/usersTable";

const useStyles = makeStyles({
  root: {
    "& > *": {
      margin: "10px 30px",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
});

const initialUser = {
  nombre: "",
  apellido: "",
  email: "",
  idRol: "",
  idArt: "",
  idPrestador: "",
};

const UsersContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const users = useSelector((state) => state.users.listUsers);
  const loading = useSelector((state) => state.users.listUsers.isFetching);
  // Ahora la mesa de ayuda cuenta con un usuario administrador
  // de tipo "Soporte". Su única funcionalidad habilitada
  // es impersonar usuarios.
  const esSoporte = useSelector(
    (state) => state.auth.roles?.tipo === "Soporte",
  );

  const [openEdit, setOpenEdit] = useState(false);
  const [user, setUser] = useState(initialUser);
  const [addingNewUser, setAddingNewUser] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    dispatch(getListUsers());
  }, []);

  const handleCloseEdit = () => {
    setUser(initialUser);
    setAddingNewUser(true);
    setOpenEdit(false);
  };

  const handleSubmitUser = (user, isNew) => {
    if (isNew) {
      dispatch(userRegister(user)).then(() => {
        dispatch(getListUsers());
        enqueueSnackbar(<p>Se ha creado el usuario correctamente!</p>, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
    } else {
      //TODO: Actualizo usuario
    }
    handleCloseEdit();
  };

  const handleDelete = () => {
    dispatch(userDelete(user)).then(() =>
      enqueueSnackbar(<p>Se ha eliminado el usuario correctamente!</p>, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }),
    );
    handleCloseDelete();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setUser(initialUser);
  };

  const handleOpenDelete = (user) => {
    setUser(user);
    setOpenDelete(true);
  };

  const handleImpersonate = (user) => {
    dispatch(impersonateUser(user)).then(() => navigate("/home"));
  };

  const handleOpenEdit = () => {
    dispatch(getUserRoles());
    setOpenEdit(true);
  };

  return (
    <div>
      <UsersTable
        data={users.list}
        loading={loading}
        title="Usuarios"
        fetchData={() => dispatch(getListUsers())}
        handleOpenDelete={handleOpenDelete}
        handleImpersonate={handleImpersonate}
      />

      {!esSoporte && (
        <div className={classes.root}>
          <Fab
            onClick={handleOpenEdit}
            disabled={loading}
            color="primary"
            aria-label="add">
            <AddIcon />
          </Fab>
        </div>
      )}

      <EditUserDialog
        user={user}
        open={openEdit}
        handleClose={handleCloseEdit}
        addingNewUser={addingNewUser}
        handleSubmit={handleSubmitUser}
      />

      <ConfirmDeleteDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title="Eliminar Usuario"
        text={`¿Está seguro que desea eliminar el usuario ${user.email}?`}
      />
    </div>
  );
};

export default UsersContainer;
