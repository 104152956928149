import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBranches } from "../../../../actions/appointments";
import { parseHTMLToReact } from "../../../../utils/util";
import PrintAndDownloadDialog from "../../../commons/dialog/PrintAndDownloadDialog";
import QRForm from "./QRForm";

const QRContainer = () => {
  const dispatch = useDispatch();
  const art = useSelector((state) => state.art.artsAdhered);
  const [openModal, setOpenModal] = useState(false);
  const [html, setHtml] = useState(null);
  const branches = useSelector((state) => state.appointments.getBranches);
  useEffect(() => {
    if (branches.data.length === 0) {
      dispatch(getBranches());
    }

  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const generatePrePrintedHTML = (qrBase64, selectedBranch) => {
    const branch = branches.data.find((e) => e.id == selectedBranch);
    const generatedHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Asistencia Turno</title>
          
      </head>
        <body>
        <style nonce="ACCMLP">
            .containerQR {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 20px;
            }
            .parrafosQR {
              margin-top: 20px;
            }
          </style>
          <style nonce="ACCMLP">
            @media print {
              @page {
              size: A4;
              margin: 35mm;
            } 
          </style>
          <div class="containerQR">
            <h1>Asistencia al Turno</h1>
            <p>Por favor, escanee el siguiente código QR para confirmar su asistencia:</p>
            <div class="qr-code">
              <img src="${qrBase64}" alt="QR Code" />
            </div>
            <div class="parrafosQR">
              <p>${branch.nombrePrestador}</p>
              <p>${branch.nombre}</p>
              <p>${branch.calle} ${branch.numero} ${branch.departamento} (${branch.codigoPostal}) ${branch.localidad} ${branch.provincia}</p>
            </div>
          </div>
        </body>
      </html>
    `;

    setHtml(generatedHtml);
    setOpenModal(true);
  };

  return (
    <>
      <QRForm handlePrintFile={generatePrePrintedHTML} />
      <PrintAndDownloadDialog
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        childComponent={
          <React.Fragment>{parseHTMLToReact(html)}</React.Fragment>
        }
        title="Imprimir QR"
        showDownloadButton={false}
        reactToPrintProps={{ suppressErrors: true }}
      />
    </>
  );
};

export default QRContainer;
