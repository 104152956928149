import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Block, Clear, Done, Edit, Print } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 10,
  },
  card: {
    display: "flex",
    marginTop: 10,
    marginBottom: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  appointmentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "65%",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "35%",
  },
  button: {
    background: "transparent",
    border: "none",
    padding: 0,
    marginTop: 2,
  },
  icon: {
    cursor: "pointer",
  },
  tooltip: {
    cursor: "pointer",
    marginRight: 15,
  },
  appointmentDate: {
    color: "grey",
  },
}));

const AppointmentDetailCards = ({
  data,
  onAttended,
  onUnAttended,
  onEdit,
  onCancelled,
  onPrintSession,
}) => {
  const classes = useStyles();
  const roles = useSelector((state) => state.auth.roles);

  const esPrestador = useSelector((state) => state.auth.roles?.PRESTADOR);
  const esSoporte = useSelector((state) => state.auth.roles?.SOPORTE);
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );

  return (
    <div className={classes.container}>
      {data.map((appointment) => (
        <Paper
          elevation={2}
          className={classes.card}
          key={appointment.idTurnoSiniestro}>
          <div className={classes.appointmentContainer}>
            <Typography variant="body2" className={classes.appointmentDate}>
              {moment(appointment.fechaHora).format("YYYY/MM/DD HH:mm")}
            </Typography>
            <Typography variant="body2">
              <b>Profesional:</b> {appointment.profesionalApellidoNombre}
            </Typography>
            <Typography variant="body2">
              <b>Matrícula:</b> {appointment.matricula}
            </Typography>
            <Typography variant="body2">
              <b>Especialidad:</b> {appointment.especialidadNombre}
            </Typography>
            <Typography variant="body2">
              <b>Observación:</b> {appointment.observacion}
            </Typography>
          </div>
          {(esPrestador || esSoporte) && (
            <div className={classes.actionsContainer}>
              <Tooltip
                arrow="top"
                title="Concurrió"
                className={classes.tooltip}>
                <button
                  className={classes.button}
                  disabled={!calendar.data.isCalendarEnabled || roles.SOPORTE}>
                  <Done
                    className={classes.icon}
                    onClick={() => onAttended(appointment)}
                  />
                </button>
              </Tooltip>
              <Tooltip
                arrow="top"
                title="No Concurrió"
                className={classes.tooltip}>
                <button
                  className={classes.button}
                  disabled={!calendar.data.isCalendarEnabled || roles.SOPORTE}>
                  <Block
                    className={classes.icon}
                    onClick={() => onUnAttended(appointment)}
                  />
                </button>
              </Tooltip>
              <Tooltip arrow="top" title="Editar" className={classes.tooltip}>
                <button
                  className={classes.button}
                  disabled={!calendar.data.isCalendarEnabled || roles.SOPORTE}>
                  <Edit
                    className={classes.icon}
                    onClick={() => onEdit(appointment)}
                  />
                </button>
              </Tooltip>
              <Tooltip arrow="top" title="Anular" className={classes.tooltip}>
                <button
                  className={classes.button}
                  disabled={!calendar.data.isCalendarEnabled || roles.SOPORTE}>
                  <Clear
                    className={classes.icon}
                    onClick={() => onCancelled(appointment)}
                  />
                </button>
              </Tooltip>
              <Tooltip
                arrow="top"
                title="Imprimir Sesión"
                className={classes.tooltip}>
                <Print
                  className={classes.icon}
                  onClick={() => onPrintSession(appointment)}
                />
              </Tooltip>
            </div>
          )}
        </Paper>
      ))}
    </div>
  );
};

export default AppointmentDetailCards;
