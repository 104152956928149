import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Visibility } from "@mui/icons-material";
import React from "react";
import CircularLoading from "../../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 100,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    borderRadius: 5,
  },
  button: {
    cursor: "pointer",
  },
  emptyState: {
    alignSelf: "center",
    marginTop: 20,
  },
}));

const AttentionsInAnotherProviderCards = ({
  data,
  loading,
  generateAttentionInAnotherProviderHTML,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularLoading />
      ) : data.length ? (
        data.map((attention) => {
          return (
            <Paper
              className={classes.card}
              key={`${attention.siniestro}-${attention.nroFormulario}`}>
              <Typography variant="body2">
                {attention.formularios[0].fechaDesde} -{" "}
                {attention.formularios[0].fechaHasta}
              </Typography>
              <Tooltip
                title="Ver atención"
                className={classes.button}
                onClick={() =>
                  generateAttentionInAnotherProviderHTML(attention)
                }>
                <Visibility />
              </Tooltip>
            </Paper>
          );
        })
      ) : (
        <Typography className={classes.emptyState} variant="body2">
          No hay atenciones disponibles
        </Typography>
      )}
    </div>
  );
};

export default AttentionsInAnotherProviderCards;
