import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Zoom,
  makeStyles,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { EditOutlined } from "@mui/icons-material";
import Event from "@mui/icons-material/Event";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableDocumentsToAdd } from "../../../actions/accidentDashboard";
import { getAccidents } from "../../../actions/accidents";
import { postAppointment } from "../../../actions/appointments";
import { getConfidentialFields } from "../../../actions/fields";
import {
  openNewForm,
  setFormsGeneralProperties,
} from "../../../actions/formsState";
import { getFormatoFechaExportacion } from "../../../utils/datetimeUtils";
import { downloadCsv } from "../../../utils/filesUtils";
import snackbarUtils from "../../../utils/snackbarUtils";
import {
  getBoolParaExportacion,
  redactFromRegistroConfidentialFields,
} from "../../../utils/util";
import AddAppointment from "../../ui/accident/accidentDashboard/appointmentsCalendar/addAppointment";
import AppointmentSession from "../../ui/accident/accidentDashboard/appointmentsCalendar/session/appointmentSession";
import ConfirmCancelChangesDialog from "../dialog/confirmCancelChangesDialog";
import ModalFPAState from "../simpleModal/modalFPAState";
import { localization, useTableStyles } from "./config/tableConfig";
import DetailsTableAuthorizations from "./utilsTable/detailsTableAuthorizations";
import TitleContainer from "./utilsTable/titleWithFetchData";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    marginLeft: 24,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    width: "95%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
  },
  containerIframe: {
    padding: theme.spacing(1, 1, 1),
    flex: 1,
  },
  icon: {
    cursor: "pointer",
  },
  mailIcon: {
    color: "#E1AD01",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  disabledButton: {
    color: "grey",
  },
  buttonTableActions: {
    background: "transparent",
    border: "none",
    margin: "0px 10px 0px 10px",
  },
  calendarWithoutNotification: { color: theme.palette.error.main },
  detailsTableAuthorizationsContainer: {
    backgroundColor: "#fafafa",
  },
}));

const appointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  asistio: false,
};

const MaterialTableComponentt = ({
  data,
  namesArts,
  loading,
  error,
  fetchData,
  time,
  handleChangeTime,
  title,
  noRefresh,
  isSearch,
  handleAccidentDetail,
  displayAccidents,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles(displayAccidents)();

  const dispatch = useDispatch();

  const providers = useSelector((state) => state.providers);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  const [openModalEstado, setOpenModalEstado] = useState(false);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState("");
  const [fechaAutorizacionSeleccionada, setFechaAutorizacionSeleccionada] =
    useState(moment(new Date()).format("YYYY-MM-DDT00:00:00"));
  const [autorizacion, setAutorizacion] = useState(null);
  const [codigoAutorizacion, setCodigoAutorizacion] = useState("");
  const [observacionesAutorizacion, setobservacionesAutorizacion] =
    useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [openAddAppointment, setOpenAddAppointment] = useState(false);
  const [openAppointmentSession, setOpenAppointmentSession] = useState(false);
  const [appointmentsCollection, setAppointmentsCollection] = useState(false);
  const [appointment, setAppointment] = useState();
  const [artId, setArtId] = useState(null);
  const [siniestro, setSiniestro] = useState(null);
  const [nroFormulario, setNroFormulario] = useState(null);
  const [fptInAvailableDocummentsToAdd, setFptInAvailableDocummentsToAdd] =
    useState({});
  const [rowDataState, setRowData] = useState({});
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  useEffect(() => {
    if (siniestro != null && artId != null) {
      dispatch(getAvailableDocumentsToAdd(siniestro, artId));
    }
  }, [siniestro, artId]);

  useEffect(() => {
    if (documentation.data.length > 0) {
      setFptInAvailableDocummentsToAdd(
        documentation.data.find((f) => f.formulario === "Fpt"),
      );
    }
  }, [documentation.isFetching]);

  useEffect(() => {
    dispatch(getAccidents());
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      handleCloseModalEstado();
    }
  }, [error, loading]);

  useEffect(() => {
    if (autorizacion && autorizacion.fechaAutorizacion) {
      setFechaAutorizacionSeleccionada(
        moment(new Date(autorizacion.fechaAutorizacion)).format(
          "YYYY-MM-DDT00:00:00",
        ),
      );
    }
  }, [autorizacion]);

  const handleOpenAddAppointment = (rowData) => {
    setOpenAddAppointment(true);
    setArtId(rowData.artMLCloud);
    setSiniestro(rowData.siniestroId);
    dispatch(
      setFormsGeneralProperties({
        idArt: rowData.artMLCloud,
        idSiniestro: rowData.siniestroId,
        trabajador: rowData.apellidoTrabajador + ' ' + rowData.nombreTrabajador,
        apellidoTrabajador: rowData.apellidoTrabajador,
        nombreTrabajador: rowData.nombreTrabajador,
        documento: rowData.documento,
      }),
    );
    setNroFormulario(rowData.nroFormulario);
    setRowData(rowData);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseAddAppointment = () => {
    setOpenAddAppointment(false);
  };

  const handleCloseAllDetails = () => {
    handleCloseAddAppointment();
    handleCloseConfirmDialog();
    handleCloseAppointmentSession();
    handleCloseModalEstado();
  };

  const handleSubmitAddAppointment = (appointment) => {
    if (appointmentsCollection) {
      // Componente AppointmentSession se encarga de crearlas, enviarlas y setear FPT si es necesario
      setAppointment(appointment);
      setOpenAppointmentSession(true);
      handleCloseAddAppointment();
      setAppointmentsCollection(false);
    } else {
      appointment = {
        ...appointment,
        fechaHora: moment(appointment.fechaHora).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
        ),
      };
      dispatch(
        postAppointment(appointment, artId, siniestro, nroFormulario),
      ).then((res) => {
        handleCloseAddAppointment();
        setAppointmentsCollection(false);
        snackbarUtils.success("Turno enviado correctamente");
        if (!noRefresh) {
          fetchData();
        }
        // Traslado:

        if (
          fptInAvailableDocummentsToAdd &&
          appointment.traslado &&
          new Date(appointment.fechaHora) >= new Date()
        ) {
          if (
            fptInAvailableDocummentsToAdd.formulario &&
            artId != null &&
            siniestro != null
          ) {
            dispatch(
              openNewForm("Fpt", fptInAvailableDocummentsToAdd.nombre, {
                professionalName: appointment.profesionalApellidoNombre,
                specialityName: appointment.especialidadNombre,
                appointmentDates: [appointment.fechaHora],
                appointmentsIds: [res.value?.data?.idTurnoSiniestro],
              }),
            );
          }
        }
      });
    }
  };

  const onAppointmentSessionCallback = () => {
    if (!noRefresh) fetchData();
  };

  const handleCloseAppointmentSession = () => {
    setOpenAppointmentSession(false);
  };

  const handleOpenModalEstado = (rowData) => {
    setAutorizacion(rowData);
    setCodigoAutorizacion(rowData.codigoAutorizacion);
    setobservacionesAutorizacion(rowData.observacionesAutorizacion);
    setOpenModalEstado(true);
  };

  const handleCloseModalEstado = () => {
    setAutorizacion(null);
    setOpenModalEstado(false);
    setEstadoSeleccionado("");
    setFechaAutorizacionSeleccionada(
      moment(new Date()).format("YYYY-MM-DDT00:00:00"),
    );
    setCodigoAutorizacion("");
    setobservacionesAutorizacion("");
  };

  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      getFormatoFechaExportacion(fila.fecha, "dd/MM/yyyy"),
      fila.siniestro ? `"${fila.siniestro}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.nombreTrabajador + " " + fila.apellidoTrabajador,
      fila.empresa ? `"${fila.empresa}"` : "",
      fila.detalle ? `"${fila.detalle}"` : "",
      getBoolParaExportacion(fila.enOtraInstitucion),
      getFormatoFechaExportacion(fila.fechaAutorizacion, "dd/MM/yyyy"),
      fila.codigoAutorizacion,
      fila.observacionesAutorizacion
        ? `"${fila.observacionesAutorizacion}"`
        : "",
    ]);

    return tableDataWithFormat;
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    dispatch(getConfidentialFields("autorizaciones"))
      .then((res) => {
        const redactedData = data.map((row) => {
          return redactFromRegistroConfidentialFields(
            row,
            res.value.data,
            "artMLCloud",
          );
        });

        const encabezado = [
          "Art",
          "Fecha",
          "Siniestro",
          "Documento",
          "Trabajador",
          "Empleador",
          "Detalle",
          "En otra institución",
          "Respondido por la Art",
          "Código de autorización",
          "Observaciones de Art",
        ];

        const formattedDataAsCsv = [
          encabezado,
          ...formatTableDataForExport(redactedData),
        ];

        const prestador = providers.list.find(
          (provider) => provider.id === providers.selectedProvider,
        );

        let csvContent = "sep=,\n";
        csvContent += `Razón Social, ${prestador ? prestador.nombre : "---"}\n`;
        csvContent += `Fecha Emisión Reporte, ${moment(new Date()).format(
          "DD/MM/YYYY",
        )}\n`;
        csvContent += "\n";
        csvContent += `${formattedDataAsCsv
          .map((e) => e.join(","))
          .join("\n")}`;

        downloadCsv(
          csvContent,
          title !== "Autorizaciones" ? `Autorizaciones${title}` : title,
        );
        snackbarUtils.success(
          "Las autorizaciones han sido descargadas con éxito.",
        );
        setIsDownloadingCsv(false);
      })
      .catch((err) => {
        snackbarUtils.error(err);
        setIsDownloadingCsv(false);
      });
  };

  const columns = [
    {
      title: "Art",
      field: "art",
      lookup: namesArts,
      filtering: !!namesArts,
      render: (rowData) => rowData.art.toUpperCase(),
      width: "9%",
    },
    {
      title: "Fecha",
      field: "fecha",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fecha).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fecha).format("DD/MM/YYYY"),
      width: "9%",
    },
    {
      title: "Siniestro",
      field: "siniestro",
      render: (rowData) => {
        if (rowData.siniestro === 0) return " ";
        return rowData.siniestro;
      },
      width: "9%",
    },
    { title: "Documento", field: "documento", width: "9%" },
    {
      title: "Trabajador",
      field: "trabajador",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
      render: (rowData) => {
        if (!rowData.nombreTrabajador) {
          return rowData.apellidoTrabajador;
        } else if (!rowData.apellidoTrabajador) {
          return rowData.nombreTrabajador;
        } else {
          return rowData.apellidoTrabajador + ", " + rowData.nombreTrabajador;
        }
      },
    },
    {
      title: "Detalle",
      field: "detalle",
      render: (rowData) => {
        if (rowData.detalle.length > 50) {
          return (
            <Tooltip title={rowData.detalle} TransitionComponent={Zoom} arrow>
              <p>
                {rowData.detalle.substring(0, 50)}
                <b>
                  <i>...[ver más]</i>
                </b>
              </p>
            </Tooltip>
          );
        } else {
          return <p>{rowData.detalle}</p>;
        }
      },
    },
    {
      title: "En otra institución",
      field: "enOtraInstitucion",
      cellStyle: {
        textAlign: "center",
      },
      width: "5%",
      lookup: { [true]: "Si", [false]: "No" },
      render: (rowData) => {
        if (rowData.enOtraInstitucion === true) {
          return <p>Sí</p>;
        }
        if (rowData.enOtraInstitucion === false) {
          return <p>No</p>;
        }
      },
    },
    {
      title: "Respondido por la Art",
      field: "fechaAutorizacion",
      customFilterAndSearch: (term, rowData) => {
        if (
          moment(rowData["fechaAutorizacion"])
            .format("DD/MM/YYYY")
            .includes(term)
        )
          return true;
        return false;
      },
      render: (rowData) => {
        if (rowData["fechaAutorizacion"] === null) {
          return "";
        }
        return moment(rowData["fechaAutorizacion"]).format("DD/MM/YYYY");
      },
      width: "9%",
    },
    { title: "Estado", field: "estado", width: "9%" },
    {
      title: "Con turnos",
      field: "conTurnos",
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        let title = "Agregar Turno";
        if (!rowData.editable) {
          title += " (Deshabilitado)";
        }
        return (
          <Tooltip title={title} arrow="top">
            <button
              className={classes.buttonTableActions}
              disabled={!rowData.editable}>
              <Badge badgeContent={rowData.cantidadTurnos || 0} color="primary">
                <Event
                  onClick={() => handleOpenAddAppointment(rowData)}
                  className={`${classes.icon} + ${!rowData.editable
                    ? classes.disabledButton
                    : !rowData.cantidadTurnos
                      ? classes.calendarWithoutNotification
                      : null
                    }`}
                />
              </Badge>
            </button>
          </Tooltip>
        );
      },
      lookup: { true: "Si", false: "No" },
      width: "5%",
    },
    {
      render: (rowData) => {
        let title = "Cambiar Estado";
        if (!rowData.editable) {
          title += " (Deshabilitado)";
        }
        return (
          <Tooltip title={title} arrow="top">
            <button
              className={classes.buttonTableActions}
              disabled={!rowData.editable}>
              <EditOutlined
                onClick={() => handleOpenModalEstado(rowData)}
                className={classes.icon}
              />
            </button>
          </Tooltip>
        );
      },
      width: "5%",
    },
  ];

  return (
    <>
      <div className={`${tableClasses.container} ${tableClasses.tablePadding}`}>
        <MaterialTable
          columns={columns}
          data={data}
          isLoading={loading || isDownloadingCsv}
          title={
            <TitleContainer
              title={title}
              noRefresh={noRefresh}
              fetchData={fetchData}
            />
          }
          actions={[
            {
              icon: "visibility",
              tooltip: "Ver detalles",
              onClick: (event, rowData) => {
                handleAccidentDetail(rowData);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                {time && (
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Duración</InputLabel>
                      <Select onChange={handleChangeTime} value={time}>
                        <MenuItem value={2}>48 horas</MenuItem>
                        <MenuItem value={7}>7 días</MenuItem>
                        <MenuItem value={30}>30 días</MenuItem>
                        <MenuItem value={100}>100 días</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: localization.headerStyle,
            rowStyle: localization.rowStyle,
            pageSize: 10,
            search: false,
            filtering: true,
            exportButton: true,
            exportCsv: (columns, dataExport) => {
              handleExportToCsv(dataExport);
            },
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: "No hay autorizaciones que mostrar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
          }}
          detailPanel={[
            {
              tooltip: "Más Información",
              render: (rowData) => (
                <div className={classes.detailsTableAuthorizationsContainer}>
                  <DetailsTableAuthorizations rowData={rowData} />
                </div>
              ),
            },
          ]}
        />
        <ModalFPAState
          open={openModalEstado}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleClose={handleCloseModalEstado}
          autorizacion={autorizacion}
          estadoSeleccionado={estadoSeleccionado}
          setEstadoSeleccionado={setEstadoSeleccionado}
          fechaAutorizacionSeleccionada={fechaAutorizacionSeleccionada}
          setFechaAutorizacionSeleccionada={setFechaAutorizacionSeleccionada}
          codigoAutorizacion={codigoAutorizacion}
          setCodigoAutorizacion={setCodigoAutorizacion}
          observacionesAutorizacion={observacionesAutorizacion}
          setobservacionesAutorizacion={setobservacionesAutorizacion}
          isAnswered={true}
          disabled={loading}
          isSearch={isSearch}
        />
        <AddAppointment
          open={openAddAppointment}
          handleSubmit={handleSubmitAddAppointment}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          appointmentModel={appointmentModel}
          openAppointmentCollection={() => setAppointmentsCollection(true)}
          artId={artId}
          openInCalendar={true}
        />
        <AppointmentSession
          appointment={appointment}
          accident={rowDataState}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          open={openAppointmentSession}
          handleClose={handleCloseAppointmentSession}
          artId={artId}
          siniestro={siniestro}
          trabajador={rowDataState.apellidoTrabajador + ' ' + rowDataState.nombreTrabajador}
          nroFormulario={nroFormulario}
          callBack={onAppointmentSessionCallback}
          hasFpt={!!fptInAvailableDocummentsToAdd}
        />
      </div>
      <div>
        <ConfirmCancelChangesDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseAllDetails}
        />
      </div>
    </>
  );
};

export default MaterialTableComponentt;
