import {
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@mui/icons-material";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  localization,
  useTableStyles,
} from "../../../../../commons/table/config/tableConfig";

const useStyles = makeStyles((theme) => ({
  appointmentsContainer: {
    padding: 0,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginLeft: 10,
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
  },
}));

const AppointmentPrintSession = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles("block");
  const tableClasses = useTableStyles()();
  const appointmentSession = useSelector(
    (state) => state.appointments.appointmentSession,
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    if (appointmentSession.data.sesionTurnos) {
      setData(
        appointmentSession.data.sesionTurnos.map((s) => {
          const tableData = { checked: true };
          s.tableData = tableData;
          return s;
        }),
      );
    } else {
      setData([]);
    }
  }, [appointmentSession.data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>IMPRIMIR SESION</Typography>
        <Tooltip title="Cerrar">
          <Close onClick={handleClose} className={classes.closeIcon} />
        </Tooltip>
      </div>
      <DialogContent className={classes.appointmentsContainer}>
        <div className={tableClasses.tablePadding}>
          <MaterialTable
            title="Turnos"
            isLoading={appointmentSession.isFetching}
            columns={[
              {
                title: "N°",
                field: "orden",
              },
              {
                title: "Día",
                render: (rowData) =>
                  moment(rowData.fechaHora).format("DD/MM/YYYY"),
              },
              {
                title: "Hora",
                render: (rowData) => moment(rowData.fechaHora).format("HH:mm"),
              },
              {
                title: "Profesional",
                render: (rowData) => appointmentSession.data.profesional,
              },
              {
                title: "Especialidad",
                render: (rowData) => appointmentSession.data.especialidad,
              },
            ]}
            data={data}
            options={{
              actionsColumnIndex: -1,
              // headerStyle: classes.headerStyle,
              // rowStyle: classes.rowStyle,
              search: false,
              selection: true,
            }}
            localization={{
              ...localization,
              body: {
                emptyDataSourceMessage: "No hay turnos que mostrar",
                filterRow: {
                  filterTooltip: "Filtrar",
                },
              },
            }}
            actions={[
              {
                tooltip: "Imprimir Sesión",
                icon: "print",
                onClick: (evt, data) => handleSubmit(data),
              },
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentPrintSession;
