import { Drawer, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import Logo from "../../../assets/images/logo_small.png";
import ListDrawer from "./listDrawer";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    borderRight: 0,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#ffffff",
  },
  drawerOpen: {
    top: "65px",
    borderRight: 0,
    height: "calc(100vh - 65px)",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    "&:hover": {
      overflowY: "auto",
    },
  },
  drawerClose: {
    top: "65px",
    borderRight: 0,
    paddingRight: -15,
    height: "calc(100vh - 65px)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    overflow: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8.5),
    },
    "&:hover": {
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: "0.4em !important",
      },
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 15,
    zIndex: 9999,
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    backgroundColor: "#ffffff",
  },
  title: {
    flexGrow: 1,
    color: "#858a93",
    marginLeft: "25%",
  },
  arrowToolbar: {
    marginLeft: 15,
  },
}));

function ResponsiveDrawer({ open, handleDrawerToggle }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}>
      <div className={classes.toolbar}>
        <img src={Logo} alt="" />
        <IconButton
          className={classes.arrowToolbar}
          onClick={handleDrawerToggle}
          size="small">
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <ListDrawer />
    </Drawer>
  );
}

export default ResponsiveDrawer;
