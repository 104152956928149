import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import PdfView from "../../../commons/file/pdfView";
import CircularLoading from "../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    backgroundColor: theme.palette.secondary.dark,
  },
  titleContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 40,
  },
  text: {
    color: "white",
    fontWeight: "bold",
    textTransform: "upperCase",
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
  },
}));

const FileView = ({ file, open, handleClose, isImage }) => {
  const classes = useStyles();

  const attachment = useSelector((state) => state.forms.attachment);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      disableBackdropClick={true}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.text}>{file.nombre}</Typography>
        </div>
        <Tooltip title="Cerrar">
          <Close onClick={handleClose} className={classes.closeIcon} />
        </Tooltip>
      </div>
      <DialogContent>
        {attachment.isFetching ? (
          <CircularLoading />
        ) : isImage ? (
          <img
            src={
              file.value
                ? file.base64Header + file.value
                : `data:${file.mimeType};base64,${attachment.data.base64}`
            }
            alt={file.nombre}
          />
        ) : (
          <PdfView
            file={
              file.value
                ? file.base64Header + file.value
                : `data:${file.mimeType};base64,${attachment.data.base64}`
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileView;
