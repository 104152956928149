import { Button, MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikDatePicker from "../../commons/formik/formikDatePicker";
import FormikSelect from "../../commons/formik/formikSelect";
import FormikTextField from "../../commons/formik/formikTextField";
import CircularLoading from "../../commons/loadingData/circularLoading";
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginRight: 20,
    width: 250,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
  errorText: {
    color: "red",
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
  },
}));

const validationSchema = yup.object().shape({
  art: yup.string().required("El ART es obligatorio"),

  startDate: yup
    .date()
    .required("La fecha desde es obligatoria")
    .typeError("Fecha inválida") // Validación para asegurar que el campo es una fecha válida
    .test(
      "startDate-before-endDate",
      "La fecha desde debe ser anterior a la fecha hasta",
      function (value) {
        const { endDate } = this.parent; // Accede al valor de endDate
        if (value && endDate && new Date(value) >= new Date(endDate)) {
          return false; // Si startDate no es anterior a endDate, devuelve false
        }
        return true; // Si la condición se cumple, devuelve true
      },
    ),

  endDate: yup
    .date()
    .required("La fecha hasta es obligatoria")
    .typeError("Fecha inválida") // Validación para asegurar que el campo es una fecha válida
    .test(
      "endDate-after-startDate",
      "La fecha hasta debe ser posterior a la fecha desde",
      function (value) {
        const { startDate } = this.parent; // Accede al valor de startDate
        if (value && startDate && new Date(value) <= new Date(startDate)) {
          return false; // Si endDate no es posterior a startDate, devuelve false
        }
        return true; // Si la condición se cumple, devuelve true
      },
    ),
});

const initialValues = {
  art: "",
  siniestro: "",
  dni: "",
  providerId: "",
  startDate: null,
  endDate: null,
};

const NovedadesSearchForm = ({
  isSearching,
  arts,
  providers,
  handleSubmitSearch,
  model,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        {!isSearching ? (
          <Formik
            initialValues={model ?? initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitSearch}>
            {({ values }) => (
              <Form>
                <FormikSelect
                  name="art"
                  label="Art"
                  variant="standard"
                  className={classes.input}>
                  {arts.map((art) => (
                    <MenuItem value={art.id} key={art.id}>
                      {art.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikTextField
                  name="siniestro"
                  label="Siniestro"
                  className={classes.input}
                />
                <FormikTextField
                  name="dni"
                  label="DNI"
                  className={classes.input}
                />
                <FormikSelect
                  name="providerId"
                  label="Prestador"
                  variant="standard"
                  className={classes.input}>
                  {providers.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikDatePicker
                  name="startDate"
                  label="Fecha Desde"
                  className={classes.input}
                />
                <FormikDatePicker
                  name="endDate"
                  label="Fecha Hasta"
                  className={classes.input}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    color="primary"
                    disabled={isSearching}>
                    Buscar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularLoading />
        )}
      </Paper>
    </div>
  );
};

export default NovedadesSearchForm;
