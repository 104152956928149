import {
  AppBar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@mui/icons-material";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectProvider } from "../../../actions/providers";
import IconMenuHelp from "./iconHelp";
import IconToJira from "./iconToJira";
import IconUser from "./iconUser";
const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0 0 0 0",
    backgroundColor: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "rgb(158, 158, 157)",
  },
  hide: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "rgb(158, 158, 157)",
  },
  button: {
    root: {
      color: "#858a93",
    },
    input: {
      color: "white",
    },
  },
  prestadorFormControl: {
    marginLeft: -20,
    width: 300,
  },
  providerOption: {
    fontSize: 16,
  },
}));

const HeaderAppBar = ({ open, handleDrawerToggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const providers = useSelector((state) => state.providers);
  const roles = useSelector((state) => state.auth.roles);

  const prevSelectProvider = useRef();

  useEffect(() => {
    if (!prevSelectProvider.current && providers.selectedProvider) {
      prevSelectProvider.current = providers.selectedProvider;
    }
  }, [prevSelectProvider, providers.selectedProvider]);

  useEffect(() => {
    // Los usuarios ART pueden buscar siniestros, y cuando entran
    // en "ver detalle siniestro", fuerzan un cambio de prestador para
    // que funcionen los endpoints como GetDocuments o GetPrePrintedForms, etc.
    // Es por eso que esta funcion solo deberia ejecutarse solamente si
    // el usuario es un PRESTADOR.
    if (
      roles?.PRESTADOR &&
      !!prevSelectProvider.current &&
      providers.selectedProvider &&
      prevSelectProvider.current !== providers.selectedProvider
    ) {
      setTimeout(() => window.location.reload(), 200);
    }
  }, [prevSelectProvider, providers.selectedProvider]);

  const handleChangeSelect = (event) => {
    dispatch(changeSelectProvider(event.target.value));
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}>
          <Menu />
        </IconButton>

        {roles && roles.PRESTADOR && (
          <Grid item xs={10} lg={5}>
            <FormControl
              variant="standard"
              className={classes.prestadorFormControl}>
              <InputLabel>Prestador</InputLabel>
              <Select
                label="Prestador"
                onChange={handleChangeSelect}
                defaultValue={
                  providers.selectedProvider === false
                    ? ""
                    : providers.selectedProvider
                }>
                {providers.list.map((provider) => (
                  <option
                    key={provider.id}
                    value={provider.id}
                    className={classes.providerOption}>
                    {provider.nombre}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid container justify="flex-end" />
        <IconToJira />
        <IconUser />
        <IconMenuHelp />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderAppBar;
