import { Grid, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../../../actions/login";
import FormikCaptcha from "../../../../commons/formik/formikCaptcha";
import AutoLogin from "./autologin";


const useStyles = makeStyles((theme) => ({
    gridContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(120deg, #96d43a, #539aff)",
    },
}));

const LOGIN_CREDENTIAL = {
    password: "4ttendanceM1CL0UD",
    userName: "attendance",
    token: ""
}

const PublicAttendance = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const captchaRef = useRef(null);

    const handleQRLogin = (values) => {
        dispatch(loginUser(values, values.UserName))
            .catch((error) => {
                captchaRef.current?.reset();
            });
    };



    return (
        <Grid container alignContent="center" alignItems="center" className={classes.gridContainer}>
            <Formik
                initialValues={LOGIN_CREDENTIAL}
                onSubmit={handleQRLogin}
            >
                <Form>
                    <AutoLogin />
                    <FormikCaptcha ref={captchaRef} />
                </Form>
            </Formik>

        </Grid>


    )
}

export default PublicAttendance;