import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Clear } from "@mui/icons-material";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        marginBottom: 15,
    },
    card: {
        display: "flex",
        marginTop: 10,
        marginBottom: 5,
    },
    infoContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
    },
    actionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        marginLeft: 8,
        paddingLeft: 15,
    },
    turnosContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "70%",
    },
    button: {
        cursor: "pointer",
        marginRight: 15,
    },
    disabledButton: {
        marginRight: 15,
        color: "grey",
    },
}));

const FpaAppointmentCard = ({ turnos, disabled, handleDelete }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {turnos.map((turno, indiceTurno) => (
                !turno.DesvincularNroTransaccionFpa && (
                    <div
                        className={classes.card}
                        key={indiceTurno}>
                        <Paper elevation={2} className={classes.infoContainer}>
                            <div className={classes.turnosContainer}>
                                <div>
                                    <Typography>Fecha: {moment(turno.fechaHora).format(
                                        "DD/MM/YYYY HH:mm")}</Typography>
                                    <Typography>Profesional: {turno.profesionalApellidoNombre} (MP. {turno.matricula}) - {turno.especialidadNombre}</Typography>
                                </div>
                            </div>
                        </Paper>
                        <Paper elevation={2} className={classes.actionContainer}>
                            <Tooltip
                                title="Eliminar"
                                className={
                                    disabled
                                        ? classes.disabledButton
                                        : classes.button
                                }
                                onClick={() => {
                                    if (!disabled) {
                                        handleDelete(indiceTurno, turnos);
                                    }
                                }}>
                                <Clear />
                            </Tooltip>
                        </Paper>
                    </div>
                )

            ))}
        </div>
    );
}

export default FpaAppointmentCard;