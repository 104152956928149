import axios from "axios";

export const GET_ACCIDENTS = "GET_ACCIDENTS";
export const GET_ACCIDENTS_ART = "GET_ACCIDENTS_ART";
export const GET_ACCIDENTS_FILTER = "GET_ACCIDENTS_FILTER";
export const CLEAN_ACCIDENTS_FILTER = "CLEAN_ACCIDENTS_FILTER";
export const GET_ACCIDENT_FORM = "GET_ACCIDENT_FORM";
export const POST_ACCIDENT_FORM = "POST_ACCIDENT_FORM";
export const CLEAN_ACCIDENT_FORM = "CLEAN_ACCIDENT_FORM";
export const GET_ACCIDENT_FACADE_OPEN = "GET_ACCIDENT_FACADE_OPEN";
export const GET_ACCIDENT_FACADE = "GET_ACCIDENT_FACADE";
export const RE_OPEN_ACCIDENT = "RE_OPEN_ACCIDENT";
export const GET_REASONS_CLOUSURE = "GET_REASONS_CLOUSURE";
export const POST_CLOSE_ACCIDENT = "POST_CLOSE_ACCIDENT";
export const GET_ACCIDENT_LOGS = "GET_ACCIDENT_LOGS";

export const getAccidents = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ACCIDENTS,
      payload: axios
        .get(`accident?ProviderId=${selectedProvider}&InTreatment=true`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAccidentsArt = (params) => (dispatch) => {
  return dispatch({
    type: GET_ACCIDENTS_ART,
    payload: axios
      .get(`accident/art?${params}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getAccidentsWithFilters = (params) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ACCIDENTS_FILTER,
      payload: axios
        .get(`accident?ProviderId=${selectedProvider}${params}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const cleanAccidentsWithFilters = () => ({
  type: CLEAN_ACCIDENTS_FILTER,
});

export const getAccidentForm =
  (idArt, tipoDocumento, numeroDocumento, siniestro, diagnosticoOMS) =>
  (dispatch) => {
    dispatch({
      type: GET_ACCIDENT_FORM,
      payload: axios
        .get(
          `accident/form?idArt=${idArt}${
            tipoDocumento ? `&tipoDocumento=${tipoDocumento}` : ""
          }${numeroDocumento ? `&numeroDocumento=${numeroDocumento}` : ""}${
            siniestro ? `&siniestro=${siniestro}` : ""
          }${diagnosticoOMS ? `&diagnosticoOMS=${diagnosticoOMS}` : ""}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const cleanAccidentForm = () => ({
  type: CLEAN_ACCIDENT_FORM,
});

export const postAccidentForm =
  (form, idArt, providerId) => (dispatch, getState) => {
    const provider = providerId || getState().providers.selectedProvider;
    const roles = getState().auth.roles;
    const verifyUser = roles.tipo != "Soporte";
    return dispatch({
      type: POST_ACCIDENT_FORM,
      payload: axios
        .post(
          `accident/form?idArt=${idArt}&providerId=${provider}&verifyUser=${verifyUser}`,
          form,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAccidentFacadeAndOpen =
  (idArt, tipoDocumento, numeroDocumento) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    return dispatch({
      type: GET_ACCIDENT_FACADE_OPEN,
      payload: axios
        .get(
          `accident/facadeAndOpen?providerId=${selectedProvider}&idArt=${idArt}&tipoDocumento=${tipoDocumento}&numeroDocumento=${numeroDocumento}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAccidentFacade =
  (idArt, tipoDocumento, numeroDocumento, providerId, idSiniestro) =>
  (dispatch, getState) => {
    // Si providerId no está presente, se utiliza el valor del estado
    const provider = providerId || getState().providers.selectedProvider;
    const roles = getState().auth.roles;
    const validateSiniestro = roles.tipo != "Soporte";
    return dispatch({
      type: GET_ACCIDENT_FACADE,
      payload: axios
        .get(
          `accident/facade?providerId=${provider}&idArt=${idArt}&tipoDocumento=${tipoDocumento}&numeroDocumento=${numeroDocumento}&idSiniestro=${idSiniestro}&validateSiniestro=${validateSiniestro}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAccidentLogs = (idArt, idSiniestro) => (dispatch) => {
  return dispatch({
    type: GET_ACCIDENT_LOGS,
    payload: axios
      .get(
        `accident/numeroSiniestroArtLogs?idArt=${idArt}&accident=${idSiniestro}`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const reOpenAccident = (accident) => (dispatch, getState) => {
  const { mlCloudCoreArtId, tipoDocumento, documento, siniestro, idSiniestro } =
    accident;
  const { selectedProvider } = getState().providers;
  return dispatch({
    type: RE_OPEN_ACCIDENT,
    payload: axios
      .get(
        `accident/reOpen?providerId=${selectedProvider}&idArt=${mlCloudCoreArtId}&tipoDocumento=${tipoDocumento}&numeroDocumento=${documento}&idSiniestro=${idSiniestro}&siniestro=${siniestro}`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getReasonsClousure = (accident) => (dispatch) => {
  const { mlCloudCoreArtId } = accident;
  return dispatch({
    type: GET_REASONS_CLOUSURE,
    payload: axios
      .get(`accident/reasonClousure?idArt=${mlCloudCoreArtId}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const close = (accident, data) => (dispatch) => {
  const { mlCloudCoreArtId, idSiniestro } = accident;
  return dispatch({
    type: POST_CLOSE_ACCIDENT,
    payload: axios
      .post(
        `accident/close?idArt=${mlCloudCoreArtId}&siniestro=${idSiniestro}`,
        data,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
