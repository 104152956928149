import { Button, MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";
import CircularLoading from "../../../commons/loadingData/circularLoading";
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginRight: 20,
    width: 250,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
  errorText: {
    color: "red",
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
  },
}));

const validationSchema = yup.object().shape({
  art: yup.string().required("El ART es obligatorio"),
  startDate: yup
    .date()
    .required("La fecha desde es obligatoria")
    .typeError("Fecha inválida")
    .test(
      "startDate-before-endDate",
      "La fecha desde debe ser anterior a la fecha hasta",
      function (value) {
        const { endDate } = this.parent;
        if (value && endDate && new Date(value) >= new Date(endDate)) {
          return false;
        }
        return true;
      },
    ),

  endDate: yup
    .date()
    .required("La fecha hasta es obligatoria")
    .typeError("Fecha inválida")
    .test(
      "endDate-after-startDate",
      "La fecha hasta debe ser posterior a la fecha desde",
      function (value) {
        const { startDate } = this.parent;
        if (value && startDate && new Date(value) <= new Date(startDate)) {
          return false;
        }
        return true;
      },
    ),

  provider: yup
    .string()
    .nullable()
    .test(
      "is-valid-cuit",
      "El CUIT debe tener el formato correcto (XX-XXXXXXXX-X)",
      (value) => {
        // Si el campo tiene valor, validamos que tenga el formato correcto
        if (value) {
          const cuitRegex = /^\d{2}-\d{8}-\d{1}$/;
          return cuitRegex.test(value);
        }
        return true; // Si el campo está vacío, no validamos (permitimos que sea opcional)
      },
    ),
});

const AppointmentsSearchForm = ({
  isSearching,
  arts,
  estados,
  initialValues,
  handleSubmitSearch,
  setIdArt,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        {!isSearching ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitSearch}>
            {({ values }) => (
              <Form>
                <FormikSelect
                  name="art"
                  label="Art"
                  variant="standard"
                  callBack={(value) => {
                    setIdArt(value);
                  }}
                  className={classes.input}>
                  {arts.map((art) => (
                    <MenuItem value={art.id} key={art.id}>
                      {art.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikTextField
                  name="siniestro"
                  label="Siniestro"
                  className={classes.input}
                />
                <FormikTextField
                  name="dni"
                  label="DNI"
                  className={classes.input}
                />
                <FormikTextField
                  name="provider"
                  label="Prestador"
                  variant="standard"
                  className={classes.input}
                />
                <FormikSelect
                  name="state"
                  label="Estado"
                  variant="standard"
                  className={classes.input}>
                  {estados.map((estado) => (
                    <MenuItem key={estado.id} value={estado.nombre}>
                      {estado.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikDatePicker
                  name="startDate"
                  label="Fecha Desde"
                  className={classes.input}
                />
                <FormikDatePicker
                  name="endDate"
                  label="Fecha Hasta"
                  className={classes.input}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    color="primary"
                    disabled={isSearching}>
                    Buscar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <CircularLoading />
        )}
      </Paper>
    </div>
  );
};

export default AppointmentsSearchForm;
