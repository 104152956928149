import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import ConfirmCancelChangesDialog from "../../commons/dialog/confirmCancelChangesDialog";
import { formBehaviourEnum } from "../../commons/enum/formEnums";
import AmContainer from "./am/amContainer";
import FormContainerCards from "./formContainerCards";
import FpaContainer from "./fpa/fpaContainer";
import FptContainer from "./fpt/fptContainer";
import EditFormHeader from "./headers/editFormHeader";
import NewFormHeader from "./headers/newFormHeader";
import IamiContainer from "./iami/iamiContainer";
import IdrContainer from "./idr/idrContainer";
import IetContainer from "./iet/ietContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    padding: 0,
  },
  documentsContainer: {
    display: "flex",
    flex: 1,
    backgroundColor: "#f5f5f5",
  },
  documentContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 3,
  },
}));

/**
 *
 * @param {string?} open: Nombre del form que esta abierto actualmente. Null si no hay ninguno abierto
 * @param {function} handleDialogClose: Función a utilizar cuando se intente cerrar el dialog sin enviar el form.
 * @param {object} selectedDocument: En caso de que el form existe previamente, aca se envian datos revelantes como nroFormulario, idResError.
 * @param {function} handleClose: Función de cerrar cuando el form se envia.
 * @param {string} formTitle: En caso que es un nuevo form, se pasa el titulo del form ejemplo (Formulario de Pedido de Transporte)
 * @param {enum} behaviour: Un valor del enum formBehaviourEnum. En bas ea este valor cambian algunos comportamientos del componente. Ejemplo: si es MODIFY_ACCIDENT_DOCUMENT se muestra la lista de otros documentos del siniestro
 * @param {array} documentList: Lista de documentos (seleccionables) a mostrar mientras se edita el form
 * @param {function} changeSelectedDocument: Funcion que pasandole un documento cambia si es otro documento
 * @param {bool} loaded: Es true si el form tiene todo lo necesario para ser editado
 * @param {function} setLoaded: Funcion para modificar loaded
 * @param {bool} loadingForm: Esto marca si el modelo/form esta siendo cargaddo o no.
 * @param {bool} isView: Para saber si es un documento nuevo o la vista de uno ya creado anteriormente
 * @param {bool?} disabled: Si este valor es distinto de null ignora los permisos del usuario y siempre permite o no la edicion del form.
 * @param {number} idSiniestro
 * @param {number} idArt
 * @param {function} postFormCallback: Funcion que se ejecuta despues de que el form se guarde correctamente
 * @param {bool} openCancelDialog: Si es true, muestra el dialogo respecto a cancelar el llenado del form.
 * @param {function} handleCancelFormCancelDialog: Cierra la ventana de dialogo sin cancelar el form
 * @param {function} handleConfirmFormCancelDialog: Cierra la pantalla de form cancelandolo
 * @param {string} art: Nombre de la art
 * @param {bool} errorLoadingForm: Esto es true solo si el ultimo form/model en cargarse dio error
 * @returns Dialog con el form correspondiente
 */
const Form = ({
  openForm,
  handleDialogClose,
  selectedDocument,
  handleClose,
  formTitle,
  behaviour,
  documentList,
  changeSelectedDocument,
  loaded,
  setLoaded,
  loadingForm,
  isView,
  disabled,
  idSiniestro,
  idArt,
  postFormCallback,
  openCancelDialog,
  handleCancelFormCancelDialog,
  handleConfirmFormCancelDialog,
  artName,
  commonProperties,
  errorLoadingForm,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={!!openForm}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="lg"
        disableBackdropClick={true}>
        {selectedDocument ? (
          <EditFormHeader
            handleClose={handleClose}
            commonProperties={commonProperties}
            artName={artName}
            idSiniestro={idSiniestro}
          />
        ) : (
          <NewFormHeader formName={formTitle} handleClose={handleDialogClose} loading={loadingForm && !errorLoadingForm} />
        )}
        <DialogContent className={classes.container}>
          {documentList &&
            (behaviour == formBehaviourEnum.MODIFY_ACCIDENT_DOCUMENT ||
              behaviour == formBehaviourEnum.ATTENTION_IN_OTHER_PROVIDER) && (
              <div className={classes.documentsContainer}>
                <FormContainerCards
                  data={documentList}
                  selectedDocument={selectedDocument}
                  changeSelectedDocument={changeSelectedDocument}
                  disableChange={!loaded}
                  commonProperties={commonProperties}
                />
              </div>
            )}
          <div className={classes.documentContainer}>
            {!loadingForm &&
              {
                iami: (
                  <IamiContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                  />
                ),
                iet: (
                  <IetContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                  />
                ),
                fpa: (
                  <FpaContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                    trabajador={commonProperties.trabajador}
                  />
                ),
                fpt: (
                  <FptContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                  />
                ),
                am: (
                  <AmContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                  />
                ),
                idr: (
                  <IdrContainer
                    handleClose={handleClose}
                    isView={isView}
                    setLoaded={setLoaded}
                    selectedDocument={selectedDocument}
                    disabled={disabled}
                    idSiniestro={idSiniestro}
                    idArt={idArt}
                    postFormCallback={postFormCallback}
                    handleOpenCancelDialog={handleDialogClose}
                  />
                ),
              }[openForm]}
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmCancelChangesDialog
        open={openCancelDialog}
        handleClose={handleCancelFormCancelDialog}
        handleConfirm={handleConfirmFormCancelDialog}
      />
    </>
  );
};

export default Form;
