import { Chip, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getFormatoFechaExportacion } from "../../../../utils/datetimeUtils";
import { downloadCsv } from "../../../../utils/filesUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import {
  localization,
  useTableStyles,
} from "../../../commons/table/config/tableConfig";
import TitleContainer from "../../../commons/table/utilsTable/titleWithFetchData";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    width: "10px",
  },
  desvioIcon: {
    fill: "#e6d400",
    width: 28,
    height: 28,
    marginTop: 5,
    cursor: "pointer",
  },
  detailsTableContainer: {
    backgroundColor: "#fafafa",
  },
  error: {
    borderColor: "#f37c578f",
    color: "white",
    backgroundColor: "#FF9A78",
    fontFamily: "Roboto, sans-serif",
  },
  success: {
    borderColor: "green",
    backgroundColor: "#00800094",
    color: "white",
    fontFamily: "Roboto, sans-serif",
  },
}));

const AppointmentsTable = ({
  data,
  namesArts,
  loading,
  noRefresh,
  fetchData,
  handleAppointmentDetail,
  estados,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = useTableStyles()();

  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const estadosLookup = estados.reduce((acc, estado) => {
    acc[estado.nombre] = estado.nombre; // Clave y valor son iguales
    return acc;
  }, {});

  const columns = [
    {
      title: "Art",
      field: "art",
      width: "10%",
      lookup: namesArts,
      render: (rowData) => rowData.art.toUpperCase(),
      filtering: !!namesArts,
    },
    {
      title: "Estado",
      field: "estado",
      lookup: estadosLookup,
      render: (rowData) => rowData.estado?.trim(),
    },
    {
      title: "Siniestro",
      field: "siniestro",
      width: "10%",
      render: (rowData) => {
        if (rowData.siniestro === "0") return " ";
        return rowData.siniestro;
      },
    },
    { title: "Documento", field: "documento", width: "10%" },
    {
      title: "Empleador",
      field: "empleador",
      width: "20%",
    },
    {
      title: "Prestador",
      field: "prestador",
    },
    {
      title: "Fecha Turno",
      field: "fechaTurno",
      width: "10%",
      cellStyle: {
        textAlign: "center",
      },
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaTurno).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => {
        if (rowData.fechaTurno === null) {
          return "";
        }
        const diff = moment(rowData.fechaTurno).diff(Date.now(), "days");

        return (
          <Chip
            variant="outlined"
            label={moment(rowData.fechaTurno).format("DD/MM/YYYY")}
            className={diff < 0 ? classes.error : classes.success}
          />
        );
      },
    },
    {
      title: "Fecha Procesado",
      field: "fechaProcesado",
      filtering: true,
      cellStyle: {
        textAlign: "center",
      },
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaProcesado).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => {
        if (rowData.fechaProcesado === null) {
          return "";
        }
        const diff = moment(rowData.fechaProcesado).diff(Date.now(), "days");

        return (
          <Chip
            variant="outlined"
            label={moment(rowData.fechaProcesado).format("DD/MM/YYYY")}
            className={diff < 0 ? classes.error : classes.success}
          />
        );
      },
    },
  ];

  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      fila.estado ? `"${fila.estado}"` : "",
      fila.siniestro ? `"${fila.siniestro}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.empleador ? `"${fila.empleador}"` : "",
      fila.prestador ? `"${fila.prestador}"` : "",
      getFormatoFechaExportacion(fila.fechaTurno, "P"),
      getFormatoFechaExportacion(fila.fechaProcesado, "P"),
    ]);

    return tableDataWithFormat;
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    const encabezado = [
      "Art",
      "Estado",
      "Siniestro",
      "Documento",
      "Empleador",
      "Prestador",
      "Fecha Turno",
      "Fecha Procesado",
    ];

    const formattedDataAsCsv = [encabezado, ...formatTableDataForExport(data)];

    let csvContent = "sep=,\n";
    csvContent += `${formattedDataAsCsv.map((e) => e.join(",")).join("\n")}`;

    downloadCsv(csvContent, "Turnos");
    snackbarUtils.success("Los turnos han sido descargados con éxito.");
    setIsDownloadingCsv(false);
  };

  return (
    <div className={`${tableClasses.tablePadding}`}>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={loading || isDownloadingCsv}
        locale="fr"
        title={
          <TitleContainer
            title="Turnos"
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        }
        actions={[
          {
            icon: "visibility",
            tooltip: "Ver detalles",
            onClick: (event, rowData) => {
              handleAppointmentDetail(rowData);
            },
            className: classes.actionButton,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: true,
          // eslint-disable-next-line no-shadow
          exportCsv: (columns, dataExport) => {
            handleExportToCsv(dataExport);
          },
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay registros que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    </div>
  );
};

export default AppointmentsTable;
