import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProvider,
  deleteProvider,
  getProviders,
  updateProvider,
} from "../../../actions/providers";
import ConfirmDeleteDialog from "../../commons/dialog/confirmDeleteDialog";
import EditProviderDialog from "../../commons/dialog/editProviderDialog";
import ProvidersTable from "../../commons/table/providersTable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "10px 30px",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const initialProvider = {
  nombre: "",
  apiKeyValidadorSiniestros: "",
};

const ProviderContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const providers = useSelector((state) => state.providers);
  const token = useSelector((state) => state.auth.token);

  const [openEdit, setOpenEdit] = useState(false);
  const [provider, setProvider] = useState(initialProvider);
  const [addingNewProvider, setAddingNewProvider] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    dispatch(getProviders(token));
  }, []);

  const handleSubmitProvider = (provider, isNew) => {
    if (isNew) {
      dispatch(createProvider(provider));
    } else {
      dispatch(updateProvider(provider));
    }
    handleCloseEdit();
  };

  const handleOpenEdit = (providerSelected) => {
    setProvider(providerSelected);
    setAddingNewProvider(false);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setProvider(initialProvider);
    setAddingNewProvider(true);
    setOpenEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteProvider(provider.id));
    handleCloseDelete();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setProvider(initialProvider);
  };

  const handleOpenDelete = (provider) => {
    setProvider(provider);
    setOpenDelete(true);
  };

  return (
    <div>
      <ProvidersTable
        data={providers.listProviders.list}
        loading={providers.listProviders.isFetching}
        fetchData={() => dispatch(getProviders(token))}
        handleOpenEdit={handleOpenEdit}
        handleDelete={handleOpenDelete}
      />

      <div className={classes.root}>
        <Fab
          onClick={() => setOpenEdit(true)}
          disabled={providers.listProviders.isFetching}
          color="primary"
          aria-label="add">
          <AddIcon />
        </Fab>
      </div>

      <EditProviderDialog
        provider={provider}
        open={openEdit}
        handleClose={handleCloseEdit}
        addingNewProvider={addingNewProvider}
        handleSubmit={handleSubmitProvider}
      />

      <ConfirmDeleteDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title="Eliminar Prestador"
        text={`¿Está seguro que desea eliminar el prestador ${provider.nombre}?`}
      />
    </div>
  );
};

export default ProviderContainer;
