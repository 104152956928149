import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import { getAccidents } from "../../../../actions/accidents";
import AccidentTable from "../../../commons/table/accidentTable";
import AccidentDashboardContainer from "../accidentDashboard/accidentDashboardContainer";
import EditAccidentContainer from "../edit/editAccidentContainer";
import CloseAccident from "../modal/closeAccident";

const SiniestrosContainer = () => {
  const dispatch = useDispatch();

  const accidents = useSelector((state) => state.accidents);
  const art = useSelector((state) => state.art);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  const [displayAccidents, setDisplayAccidents] = useState("block");
  const [accidentToEdit, setAccidentToEdit] = useState(null);
  const [openModalCloseAccident, setOpenModalCloseAccident] = useState(false);
  const [accident, setAccident] = useState(null);

  useEffect(() => {
    dispatch(getAccidents());
  }, []);

  const handleAccidentDetail = (accident) => {
    dispatch(setSelectedAccident({ ...accident }));
    setDisplayAccidents("none");
  };

  const handleOpenModal = (accident) => {
    setAccident(accident);
    setOpenModalCloseAccident(true);
  };

  const handleCloseModal = () => {
    setOpenModalCloseAccident(false);
  };
  return (
    <div>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer
          setDisplayAccidents={setDisplayAccidents}
          traslado={documentation.data.find((f) => f.formulario === "Fpt")}
        />
      )}
      <AccidentTable
        data={accidents.list}
        namesArts={art.shortNameArt}
        loading={accidents.isFetching}
        error={accidents.didInvalidate}
        fetchData={() => dispatch(getAccidents())}
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
        handleEdit={(accident) => setAccidentToEdit(accident)}
        openModalCloseAccident={handleOpenModal}
      />
      <EditAccidentContainer
        accident={accidentToEdit}
        handleClose={() => setAccidentToEdit(null)}
      />

      <CloseAccident
        accident={accident}
        open={openModalCloseAccident}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default SiniestrosContainer;
