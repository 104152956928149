import { makeStyles } from "@material-ui/core";
import { Loop } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  title: {
    marginRight: "16px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "large",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    color: "#484848",
  },
  icon: {
    color: "##1f1f42",
    "&:hover, &:focus": {
      cursor: "pointer",
    },
  },
});

const titleWithFetchData = ({ title, noRefresh, fetchData }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      {!noRefresh && fetchData && (
        <Loop onClick={fetchData} className={classes.icon} />
      )}
    </div>
  );
};

export default titleWithFetchData;
