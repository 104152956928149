import { useFormikContext } from "formik"
import { useEffect } from "react"

const AutoLogin = () => {

    const { values, submitForm } = useFormikContext()

    useEffect(() => {
        if (!CONFIG.useCaptcha || (values?.token && values.token != "")) {
            submitForm()
        }
    }, [values])

    return null
}

export default AutoLogin;