import { Typography, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getFormProfessionalSpecialties,
  getFormProfessionals,
} from "../../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../../utils/datetimeUtils";
import FormikActions from "../../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../../commons/formik/formikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/formikCheckbox";
import FormikDateTimePicker from "../../../../commons/formik/formikDateTimePicker";
import FormikTextField from "../../../../commons/formik/formikTextField";
import FormikTextFieldSpeech from "../../../../commons/formik/formikTextFieldSpeech";
import CircularLoading from "../../../../commons/loadingData/circularLoading";

const useStyles = makeStyles({
  rowContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    marginBottom: 10,
  },
  input: {
    flex: 1,
  },
  profesional: {
    flex: 1.5,
    marginRight: 20,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
  label: {
    paddingLeft: 25,
    display: "flex",
    alignItems: "center",
    "& input": {
      marginLeft: 15,
    },
  },
  checkbox: {
    marginLeft: 20,
  },
});

const validation = (appointmentsSession, enablePastDates) =>
  Yup.object({
    fechaHora: Yup.date()
      .required("Campo obligatorio")
      .when([], {
        is: () => !enablePastDates,
        then: (schema) =>
          schema.min(
            new Date(Date.now()),
            "La fecha del turno no puede ser menor a la fecha actual",
          ),
        otherwise: (schema) => schema, // Mantener el esquema sin restricciones adicionales
      })
      .typeError("Fecha inválida")
      .test({
        name: "fechaHora",
        message: "Ya tiene un turno cargado para esta fecha y hora",
        test: function (value) {
          if (appointmentsSession) {
            const sameAppointment = appointmentsSession.find((a) => {
              return a.fechaHora.getTime() === value.getTime();
            });
            return sameAppointment ? false : true;
          } else {
            return true;
          }
        },
      }),
    especialidad: Yup.string().required("Campo obligatorio").nullable(),
    profesionalApellidoNombre: Yup.string()
      .required("Campo obligatorio")
      .nullable(),
    matricula: Yup.string().required("Campo obligatorio"),
    observacion: Yup.string().max(200, "Máximo de 200 caracteres.").nullable(),
  });

const defaultAppointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  asistio: false,
};

const AddAppointment = ({
  open,
  handleOpenConfirmDialog,
  handleSubmit,
  appointmentModel = defaultAppointmentModel,
  especialidadesProfesional,
  session,
  appointmetsSession,
  appointmentDetail,
  artId,
  errorProfessionalsDuplicated,
  openAppointmentCollection,
  openInCalendar,
  enablePastDates = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const professionals = useSelector((state) => state.forms.professionals);
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const postAppointment = useSelector(
    (state) => state.appointments.postAppointment,
  );

  const isLoading =
    professionals.isFetching ||
    professionalSpecialties.isFetching ||
    postAppointment.isFetching;

  useEffect(() => {
    if (open && professionals.data.length === 0) {
      dispatch(getFormProfessionals(artId));
    }

    if (
      open &&
      !especialidadesProfesional &&
      professionalSpecialties.data.length === 0
    ) {
      dispatch(getFormProfessionalSpecialties(artId));
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleOpenConfirmDialog}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>
        {appointmentModel.idTurnoSiniestro ? "Editar Turno" : "Agregar Turno"}
      </DialogTitle>
      {isLoading ? (
        <DialogContent className={classes.loading}>
          <CircularLoading />
        </DialogContent>
      ) : (
        <Formik
          initialValues={appointmentModel}
          validationSchema={() =>
            validation(appointmetsSession, enablePastDates)
          }
          onSubmit={(values) => {
            const profesional = professionals.data.find(
              (p) => p.nombre === values.profesionalApellidoNombre,
            );
            let especialidad = professionalSpecialties.data.find(
              (e) => e.id === values.especialidad,
            );
            if (!especialidad) {
              especialidad = especialidadesProfesional.find(
                (e) => e.id === values.especialidad,
              );
            }
            values.profesional = profesional ? profesional.id : null;
            const createProfessional = !profesional ? true : false;
            handleSubmit(
              {
                ...values,
                especialidadNombre: especialidad.nombre,
                concurrio:
                  enablePastDates && values.fechaHora < new Date()
                    ? values.asistio
                      ? 1
                      : 2
                    : null,
                fromDB: appointmentModel.fromDB,
              },
              createProfessional,
              convertDateTimeToNet(values.fechaHora) ===
              convertDateTimeToNet(appointmentModel.fechaHora),
            );
          }}>
          {({ setFieldValue, values }) => (
            <Form>
              <DialogContent>
                <div className={classes.rowContainer}>
                  <FormikDateTimePicker
                    name="fechaHora"
                    label="Fecha Hora Turno *"
                    className={classes.input}
                    autoComplete="off"
                    disablePast={
                      !enablePastDates || !!appointmentModel.idTurnoSiniestro
                    }
                  />
                  <FormikCheckbox
                    name="traslado"
                    label="Traslado"
                    labelPlacement="end"
                    className={classes.checkbox}
                    disabled={appointmentModel.traslado}
                  />
                  {enablePastDates &&
                    values.fechaHora &&
                    values.fechaHora < new Date() && (
                      <FormikCheckbox
                        name="asistio"
                        label="¿Asistió?"
                        labelPlacement="end"
                        className={classes.checkbox}
                      />
                    )}
                </div>
                {convertDateTimeToNet(values.fechaHora) !==
                  convertDateTimeToNet(appointmentModel.fechaHora) &&
                  appointmentDetail ? (
                  <Typography color="error">
                    {
                      "IMPORTANTE: Al modificar la Fecha Hora de este Turno, se anulará el mismo, y se creará uno nuevo con los datos ingresados. Si posee traslado en el siguiente paso deberá ingresarlo."
                    }
                  </Typography>
                ) : (
                  enablePastDates &&
                  values.fechaHora &&
                  values.fechaHora < new Date() && (
                    <Typography color="error">
                      {
                        "IMPORTANTE: Solo se permiten turnos individuales para fechas pasadas."
                      }
                    </Typography>
                  )
                )}
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="profesionalApellidoNombre"
                    label="Profesional *"
                    options={professionals.data}
                    getOptions={() => dispatch(getFormProfessionals(artId))}
                    callBack={(value) => {
                      if (value) {
                        setFieldValue("matricula", value.matricula);
                        setFieldValue("especialidad", value.especialidad);
                      }
                    }}
                    className={classes.profesional}
                    freeSolo={true}
                    returnProp="nombre"
                    helperText="Nuevo Profesional"
                    renderOption={(option) =>
                      `${option.matricula} - ${option.nombre}`
                    }
                    disabled={session}
                  />
                  <FormikTextField
                    name="matricula"
                    label="Matrícula *"
                    type="text"
                    className={classes.input}
                    disabled={session}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="especialidad"
                    label="Especialidad *"
                    options={
                      especialidadesProfesional
                        ? especialidadesProfesional
                        : professionalSpecialties.data
                    }
                    className={classes.input}
                    disabled={session}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikTextFieldSpeech
                    margin="dense"
                    id="observacion"
                    name="observacion"
                    label="Observaciones"
                    type="text"
                    fullWidth
                    multiline
                    className={classes.input}
                  />
                </div>
                {errorProfessionalsDuplicated && (
                  <Typography color="error">
                    Ya existe un profesional con este nombre.
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <FormikActions
                  handleCancel={handleOpenConfirmDialog}
                  hasThirdButton={
                    enablePastDates
                      ? openInCalendar &&
                        (!values.fechaHora || !(values.fechaHora < new Date()))
                      : openInCalendar
                  }
                  thirdButtonText="Ingresar sesiones"
                  handleThirdButton={openAppointmentCollection}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AddAppointment;
