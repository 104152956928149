import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArts } from "../../../actions/arts";
import {
  getNovedadesConectividad,
  postWSMLARTNovedades,
} from "../../../actions/connectivityResponse";
import {
  openEditForm,
  setFormsGeneralProperties,
} from "../../../actions/formsState";
import { changeSelectProvider, getProviders } from "../../../actions/providers";
import { convertDateTimeToNet } from "../../../utils/datetimeUtils";
import snackbarUtils from "../../../utils/snackbarUtils";
import { formBehaviourEnum } from "../../commons/enum/formEnums";
import FormContainer from "../forms/formContainer";
import NovedadesSearchForm from "./novedadesSearchForm";
import NovedadesTable from "./novedadesTable";
import RespuestasNovedadesDialog from "./respuestasNovedadesDialog";

const NovedadesSearchContainer = () => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.providers.listProviders);
  const postWSMLARTNovedadesState = useSelector(
    (state) => state.connectivityResponse.postWSMLARTNovedades,
  );

  const art = useSelector((state) => state.arts);
  const roles = useSelector((state) => state.auth.roles);

  const connectivityNews = useSelector(
    (state) => state.connectivityResponse.connectivityNews,
  );
  const loading = providers.isFetching || art.isFetching;
  const tableLoading =
    connectivityNews.isFetching || postWSMLARTNovedadesState.isFetching;

  const [selectedRows, setSelectedRows] = useState([]);
  const [respuesta, setRespuesta] = useState(null);
  const [filters, setFilters] = useState(null);

  const [openRespuestasDialog, setOpenRespuestasDialog] = useState(false);

  useEffect(() => {
    dispatch(getProviders());
    dispatch(getArts());
  }, []);

  const handleOpenEditForm = (rowData) => {
    dispatch(changeSelectProvider(rowData.idPrestador, roles.SOPORTE));

    dispatch(
      setFormsGeneralProperties({
        idSiniestro: rowData.siniestro,
        idArt: rowData.mlCloudCoreArtId,
        artName: rowData.nombreArt,
        documento: rowData.documento,
        trabajador: rowData.trabajador,
        numeroHistoriaClinica: rowData.historiaClinica,
      }),
    );

    dispatch(
      openEditForm(
        rowData.comportamiento.trim(),
        formBehaviourEnum.NOVEDAD_CONECTIVIDAD,
        {
          nroFormulario: rowData.nroFormulario,
        },
        {},
      ),
    );
  };

  const handleOpenRespuestas = (rowData) => {
    setOpenRespuestasDialog(true);
    setRespuesta(rowData);
  };

  const handleCloseRespuestas = (rowData) => {
    setOpenRespuestasDialog(false);
    setRespuesta(null);
  };

  const handleSubmitSelected = () => {
    selectedRows.forEach((row) => {
      dispatch(postWSMLARTNovedades(row));
    });
    setSelectedRows([]);
    snackbarUtils.success("Novedades validadas correctamente");
  };

  const handleSubmitSearch = (values) => {
    const queryParams = {
      art: values.art,
      startDate: convertDateTimeToNet(values.startDate),
      endDate: convertDateTimeToNet(values.endDate),
      siniestro: values.siniestro,
      dni: values.dni,
      providerId: values.providerId
        ? providers.list
            .find((e) => e.id === values.providerId)
            .arts.find((el) => el.id === values.art).idPrestadorArt
        : null,
    };
    dispatch(getNovedadesConectividad(queryParams));
    setFilters(values);
  };
  return (
    <>
      <NovedadesSearchForm
        isSearching={loading}
        providers={providers.list}
        arts={art.list}
        model={filters}
        handleSubmitSearch={handleSubmitSearch}
      />
      <NovedadesTable
        handleOpenEdit={handleOpenEditForm}
        handleOpenRespuestas={handleOpenRespuestas}
        loading={tableLoading}
        setSelectedRows={setSelectedRows}
        namesArts={art.namesArts}
        handleSubmitSelected={handleSubmitSelected}
        data={connectivityNews.data}
      />
      <FormContainer />
      <RespuestasNovedadesDialog
        open={openRespuestasDialog}
        respuesta={respuesta}
        handleClose={handleCloseRespuestas}
      />
    </>
  );
};

export default NovedadesSearchContainer;
