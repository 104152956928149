import {
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../../../actions/accidentDashboard";
import {
  dismissConnectivityErrorAction,
  getConnectivityErrors,
} from "../../../../../../actions/connectivityResponse";
import snackbarUtils from "../../../../../../utils/snackbarUtils";
import CircularLoading from "../../../../../commons/loadingData/circularLoading";
import CardTitle from "../../cardTitle";
import ErroresConectividadCardDetail from "./ErroresConectividadCardDetail";

const useStyles = makeStyles((theme) => ({
  circularLoadingContainer: {
    marginBottom: "30px",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ErroresConectividadDialog = ({
  open,
  handleClose,
  selectedDocument,
  handleOpenDetail,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const erroresConectividad = useSelector(
    (state) => state.connectivityResponse.connectivityErrors,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);

  useEffect(() => {
    if (!erroresConectividad.data.find((e) => e.error)) {
      handleClose();
    }
  }, [erroresConectividad.data]);

  const desestimarErrorConectividad = (error) => {
    dispatch(
      dismissConnectivityErrorAction(
        error.idRespError,
        accident.mlCloudCoreArtId,
      ),
    ).then(() =>
      dispatch(
        getConnectivityErrors(
          selectedDocument.nroTransaccion,
          accident.mlCloudCoreArtId,
        ),
      ).then((res) => {
        dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
        snackbarUtils.success("Error desestimado correctamente");
      }),
    );
  };

  const icons = [
    {
      Icon: Close,
      tooltip: "Cerrar",
      onClick: handleClose,
      disabled: false,
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <CardTitle title="Errores" color="primary" icons={icons} />
      {erroresConectividad.isFetching ? (
        <div className={classes.circularLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <DialogContent className={classes.dialogContent}>
          {erroresConectividad.data.length ? (
            erroresConectividad.data.map(
              (e) =>
                e.error && (
                  <ErroresConectividadCardDetail
                    error={{
                      ...e,
                      observaciones: e.respuesta,
                    }}
                    handleOpenDetail={handleOpenDetail}
                    selectedDocument={selectedDocument}
                    desestimarErrorConectividad={desestimarErrorConectividad}
                  />
                ),
            )
          ) : (
            <Typography>Se han tratado los errores</Typography>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ErroresConectividadDialog;
