import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentInRange,
  getAppointmentsCalendar,
} from "../../../../actions/accidentDashboard";
import { getSupportAppointments } from "../../../../actions/appointments";
import { getArts } from "../../../../actions/arts";
import { changeSelectProvider } from "../../../../actions/providers";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import ConfirmDialog from "../../../commons/dialog/confirmDialog";
import AppointmentDetail from "../../accident/accidentDashboard/appointmentsCalendar/detail/appointmentDetail";
import AppointmentsSearchForm from "./appointmentsSearchForm";
import AppointmentsTable from "./appointmentsTable";

const estados = [
  { id: 1, nombre: "NO INFORMADOS" },
  { id: 2, nombre: "AUSENTES" },
  { id: 3, nombre: "PRESENTES" },
  { id: 4, nombre: "ANULADOS" },
  { id: 5, nombre: "PROGRAMADOS" },
];

const initialValues = {
  art: "",
  siniestro: "",
  dni: "",
  providerId: "",
  state: "",
  startDate: null,
  endDate: null,
};

const AppointmentsSearchContainer = () => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.providers);
  const arts = useSelector((state) => state.arts);
  const supportAppointments = useSelector(
    (state) => state.appointments.supportAppointments,
  );
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [idArt, setIdArt] = useState(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [appointmentsSelected, setAppointmentsSelected] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [appointment, setAppointment] = useState({});

  const loading = providers.isFetching || arts.isFetching;
  const tableLoading = supportAppointments.isFetching;

  const cargaFPT = documentation.data.find((f) => f.formulario === "Fpt")
    ? true
    : false;

  useEffect(() => {
    if (!providers.list.length && !providers.selectedProvider) {
      dispatch(getArts());
    }
    return () => {
      dispatch(changeSelectProvider(false));
    };
  }, [dispatch, providers.list.length]);

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleCloseEdit = () => {
    setAppointment({});
    setOpenEdit(false);
  };

  const handleOpenEdit = (appointment) => {
    setAppointment(appointment);
    setOpenEdit(true);
  };
  const handleClearOpenFromForm = () => {
    dispatch(clearAppointmentInRange());
    setAppointmentsSelected([]);
  };
  const handleCloseDetail = () => {
    setOpenDetail(false);
    handleClearOpenFromForm();
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleSubmitSearch = (values) => {
    const queryParams = {
      art: values.art,
      state: values.state,
      startDate: convertDateTimeToNet(values.startDate),
      endDate: convertDateTimeToNet(values.endDate),
      siniestro: values.siniestro,
      dni: values.dni,
      cuitProvider: values.provider,
      state: values.state,
    };
    dispatch(getSupportAppointments(queryParams));
  };

  const handleAppointmentDetail = (appointment) => {
    dispatch(changeSelectProvider(appointment.idPrestador, true));

    dispatch(
      getAppointmentsCalendar(
        appointment.idSiniestro,
        appointment.mlCloudCoreArtId,
        true,
      ),
    );

    setAppointmentsSelected([appointment]);
    setAppointment(appointment);
    setOpenDetail(true);
  };
  const handleCloseAllDetails = () => {
    handleClearOpenFromForm();
    handleCloseDetail();
    handleCloseEdit();
    handleCloseConfirmDialog();
  };

  return (
    <>
      <AppointmentsSearchForm
        isSearching={loading}
        arts={arts.list}
        estados={estados}
        initialValues={initialValues}
        handleSubmitSearch={handleSubmitSearch}
        setIdArt={setIdArt}
      />
      <AppointmentsTable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        estados={estados}
        namesArts={arts.shortNameArt}
        data={supportAppointments.data}
        handleAppointmentDetail={handleAppointmentDetail}
        loading={tableLoading}
      />
      <AppointmentDetail
        data={appointmentsSelected}
        open={openDetail}
        handleClose={handleCloseDetail}
        handleOpenConfirmDialog={handleOpenConfirmDialog}
        openEdit={openEdit}
        handleOpenEdit={handleOpenEdit}
        handleCloseEdit={handleCloseEdit}
        appointment={appointment}
        cargaFPT={cargaFPT}
        idArt={idArt}
      />
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseAllDetails}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
    </>
  );
};

export default AppointmentsSearchContainer;
