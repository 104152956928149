import { Grid, makeStyles, Paper } from "@material-ui/core";
import AttendanceContainer from "./attendanceContainer";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(120deg, #96d43a, #539aff)",
        width: "100vw",
        height: "100vh"
    },

    formContainer: {
        maxWidth: "100vw",
        minWidth: "250px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px 0px grey",
        borderRadius: "10px",
        padding: "30px 25px 10px 25px",
    }
}));


const AuthorizedAttendanceContainer = () => {
    const classes = useStyles();

    return (
        <>

            <Grid container justifyContent="center" className={classes.gridContainer}>
                <Paper className={classes.formContainer}>

                    <AttendanceContainer />

                </Paper>
            </Grid>
        </>
    );
}


export default AuthorizedAttendanceContainer;