import axios from "axios";
export const GET_CONNECTIVITY_ERRORS = "GET_CONNECTIVITY_ERRORS";
export const DISMISS_CONNECTIVITY_ERROR = "DISMISS_CONNECTIVITY_ERROR";
export const GET_ALL_CONNECTIVITY_ERRORS = "GET_ALL_CONNECTIVITY_ERRORS";
export const DISMISS_CONNECTIVITY_ERRORS = "DISMISS_CONNECTIVITY_ERRORS";
export const CORRECT_CONNECTIVITY_ERRORS = "CORRECT_CONNECTIVITY_ERRORS";
export const GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED =
  "GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED";
export const GET_CONNECTIVITY_RESPONSES = "GET_CONNECTIVITY_RESPONSES";
export const CLEAN_CONNECTIVITY_RESPONSES = "CLEAN_CONNECTIVITY_RESPONSES";
export const SET_TIME = "SET_TIME";
export const GET_NOVEDADES_CONECTIVIDAD = "GET_NOVEDADES_CONECTIVIDAD";
export const POST_WSMLART_NOVEDADES = "POST_WSMLART_NOVEDADES";

export const getNovedadesConectividad = (queryParams) => (dispatch) => {
  const { art, startDate, endDate, siniestro, dni, providerId } = queryParams;
  return dispatch({
    type: GET_NOVEDADES_CONECTIVIDAD,
    payload: axios
      .get(
        `/connectivityResponse/connectivityNews?art=${art}&fechaDesde=${startDate}&fechaHasta=${endDate}${
          siniestro ? `&siniestro=${siniestro}` : ""
        }${dni ? `&documento=${dni}` : ""}${
          providerId ? `&prestador=${providerId}` : ""
        }`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getConnectivityResponsesWithFilter =
  (filterModel) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;
    const { art, dni, siniestro, fechaDesde, fechaHasta } = filterModel;
    if (selectedProvider) {
      dispatch({
        type: _SUPPORT,
        payload: axios
          .get(
            `connectivityResponse/${selectedProvider}?siniestro=${siniestro}&dni=${dni}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&art=${art}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const cleanConnectivityResponsesWithFilters = () => ({
  type: CLEAN_CONNECTIVITY_RESPONSES,
});

export const dismissConnectivityErrorAction =
  (idError, artId) => (dispatch) => {
    return dispatch({
      type: DISMISS_CONNECTIVITY_ERROR,
      payload: axios
        .put(
          `connectivityResponse/dismissConnectivityError?idError=${idError}&idArt=${artId}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const correctConnectivityErrorAction =
  (idError, artId) => (dispatch) => {
    return dispatch({
      type: CORRECT_CONNECTIVITY_ERRORS,
      payload: axios
        .put(
          `connectivityResponse/correctConnectivityError?idError=${idError}&idArt=${artId}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };
export const getConnectivityErrors = (nroTransaccion, idArt) => (dispatch) => {
  return dispatch({
    type: GET_CONNECTIVITY_ERRORS,
    payload: axios
      .get(
        `connectivityResponse/connectivityErrors?nroTransaccion=${nroTransaccion}&idArt=${idArt}`,
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getAllConnectivityErrors = (timeSpan) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  return dispatch({
    type: GET_ALL_CONNECTIVITY_ERRORS,
    payload: axios
      .get(
        `connectivityResponse/allConnectivityErrors?idProvider=${selectedProvider}&timeSpan=${timeSpan}`,
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return Promise.reject(error);
      }),
  });
};

export const getRespuestasNovedades = (respuesta) => (dispatch, getState) => {
  const { mlCloudCoreArtId, nroTransaccion } = respuesta;
  return dispatch({
    type: GET_CONNECTIVITY_RESPONSES,
    payload: axios
      .get(
        `connectivityResponse/responses?idArt=${mlCloudCoreArtId}&nroTransaccion=${nroTransaccion}`,
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return Promise.reject(error);
      }),
  });
};

export const postWSMLARTNovedades = (respuesta) => (dispatch, getState) => {
  return dispatch({
    type: POST_WSMLART_NOVEDADES,
    payload: axios
      .post(`connectivityResponse/WSMLARTNovedades`, {
        idArt: respuesta.mlCloudCoreArtId,
        siniestro: respuesta.siniestro,
        nroFormulario: respuesta.nroFormulario,
        formulario: respuesta.comportamiento.trim(),
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return Promise.reject(error);
      }),
  });
};

export const getConnectivityErrorsNotProcessedCount =
  () => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;
    if (selectedProvider) {
      dispatch({
        type: GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED,
        payload: axios
          .get(
            `connectivityResponse/connectivityErrorsNotProcessedCount?idProvider=${selectedProvider}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const setTime = (time) => ({
  type: SET_TIME,
  payload: time,
});
