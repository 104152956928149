import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAccidentForm } from "../../../../actions/accidents";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import EditAccidentForm from "./editAccidentForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: 0,
    justifyContent: "center",
  },
  circularLoadingContainer: {
    flex: 1,
    marginTop: 80,
    marginBottom: 100,
  },
}));

const EditAccidentFormContainer = ({
  open,
  artId,
  handleCloseForm,
  handleSubmitForm,
  facadeAccident,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const accidentFormState = useSelector((state) => state.accidents.form);
  const postFormState = useSelector((state) => state.accidents.postForm);
  const diagnosis = useSelector((state) => state.forms.diagnosis);

  const [accidentForm, setAccidentForm] = useState(null);
  const [accidentModel, setAccidentModel] = useState(null);

  const isLoading =
    accidentFormState.isFetching ||
    diagnosis.isFetching ||
    !accidentForm ||
    !accidentModel ||
    postFormState.isFetching;

  useEffect(() => {
    if (!accidentFormState.isFetching && accidentFormState.data.model) {
      if (
        facadeAccident &&
        !accidentFormState.data.model.referenteApellidoyNombre.options.some(
          (r) => r.nombre === facadeAccident.referenteApellidoyNombre,
        )
      ) {
        accidentFormState.data.model.referenteApellidoyNombre.options.push({
          id: facadeAccident.idReferenteExterno,
          nombre: facadeAccident.referenteApellidoyNombre,
          email: facadeAccident.referenteEmail,
        });
      }
      setAccidentModel({
        nroSiniestroArt: accidentFormState.data.model.nroSiniestroArt,
        referenteApellidoyNombre:
          accidentFormState.data.model.referenteApellidoyNombre,
        diagnosticoOMS: accidentFormState.data.model.diagnosticoOMS,
      });
    }
  }, [accidentFormState.isFetching]);

  useEffect(() => {
    if (
      !accidentFormState.isFetching &&
      (facadeAccident || accidentFormState.data.form)
    ) {
      const form = facadeAccident || accidentFormState.data.form;
      setAccidentForm({
        nroSiniestroArt: form.nroSiniestroArt || "",
        diagnosticoOMS:
          accidentFormState.data.diagnoticoOMSConverted || form.diagnosticoOMS,
        referenteApellidoyNombre: form.referenteApellidoyNombre,
        idReferenteExterno: form.idReferenteExterno,
        referenteEmail: form.referenteEmail,
        siniestro: form.siniestro,
      });
    }
  }, [accidentFormState.isFetching]);

  const handleSubmit = (form) => {
    setAccidentForm(null);
    setAccidentModel(null);
    dispatch(postAccidentForm(form, artId, facadeAccident.prestadorId))
      .then((res) => handleSubmitForm(res))
      .catch(() => handleClose());
  };

  const handleClose = () => {
    setAccidentForm(null);
    setAccidentModel(null);
    handleCloseForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>Editar Siniestro</DialogTitle>
      <DialogContent className={classes.container}>
        {isLoading ? (
          <div className={classes.circularLoadingContainer}>
            <CircularLoading />
          </div>
        ) : (
          <EditAccidentForm
            model={accidentModel}
            accident={accidentForm}
            artId={artId}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            fromFacade={facadeAccident ? true : false}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditAccidentFormContainer;
