import { Card, CircularProgress } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmailContent } from "../../../../actions/emails";

const HtmlToReactParser = require("html-to-react").Parser;
const createDOMPurify = require("dompurify");
const htmlToReactParser = new HtmlToReactParser();
const DOMPurify = createDOMPurify(window);

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    marginLeft: "32px",
    marginRight: "16px",
  },
  containerPdf: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  contentDetails: {
    flex: 1,
    display: "flex",
    justifyContent: "end",
    padding: "14px",
  },
  contentAttach: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  },
  contentPdf: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  contentImages: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  details: {
    flex: 1,
    display: "flex",
    justifyContent: "start",
    padding: "14px",
  },
  loading: {
    flex: 1,
    display: "flex",
    height: "210px",
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontWeight: "bold",
  },
  textValue: {
    marginLeft: "18px",
    fontWeight: 300,
  },
  image: { padding: "20px", maxWidth: "100%" },
  pdfButton: {
    width: "1%",
  },
});

const DetailsTableEmails = ({ rowData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const emails = useSelector((state) => state.emails);

  const email = emails.list.find(
    (e) => e.idEmailEnviado === rowData.idEmailEnviado,
  );
  const content = email ? email.content : null;

  useEffect(() => {
    if (!emails.isFetchingContent && !content) {
      dispatch(
        getEmailContent(rowData.mlPointCoreArtId, rowData.idEmailEnviado),
      );
    }
  }, []);

  if (!content) {
    return (
      <div className={classes.container}>
        <Card>
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </Card>
      </div>
    );
  } else {
    const images = content.adjuntos.map((f) => {
      return f.extension.toLowerCase() !== "pdf" ? (
        <img
          alt={f.nombre}
          src={`data:image/${f.extension};base64,${f.adjunto}`}
          key={f.nombre}
          className={classes.image}
        />
      ) : null;
    });

    const pdfs = content.adjuntos.map((f) => {
      return f.extension.toLowerCase() === "pdf" ? (
        <Link
          component="button"
          variant="body2"
          key={f.nombre}
          onClick={() => {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:application/pdf;base64,${f.adjunto}`;
            downloadLink.download = f.nombre;
            downloadLink.click();
          }}
          className={classes.pdfButton}>
          <div className={classes.contentPdf}>
            <div className={classes.textValue}>
              {f.nombre.replace("-", "_").replace(/\s+/g, "")}
            </div>
            <GetAppRoundedIcon />
          </div>
        </Link>
      ) : null;
    });

    return (
      <div className={classes.container}>
        <Card>
          <div className={classes.content}>
            <div className={classes.contentDetails}>
              <div className={classes.textTitle}>Cuerpo: </div>
              <div className={classes.textValue}>
                {content.cuerpo &&
                  htmlToReactParser.parse(
                    DOMPurify.sanitize(content.cuerpo.replace(/>\s+</g, "><")),
                  )}
              </div>
            </div>
            {pdfs.length !== 0 || images.length !== 0 ? (
              <div className={classes.contentDetails}>
                <div className={classes.textTitle}>Adjuntos: </div>
                <div className={classes.contentAttach}>
                  <div className={classes.containerPdf}>{pdfs}</div>
                  <div className={classes.contentImages}>{images}</div>
                </div>
              </div>
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
};

export default DetailsTableEmails;
