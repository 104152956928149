
import * as Yup from "yup";

export const AttendanceValidation = (deviceLocation) => (
    Yup.object({
        documento: Yup.number()
            // Agrego este error aca para que aparesca debajo del campo de documento
            .test({
                name: "Location",
                params: { deviceLocation },
                message: "No se pudo acceder a su ubicación. Por favor asegurese de permitir compartir la ubicación y recargue la pagina",
                test: () => {
                    return deviceLocation.latitude != null && deviceLocation.longitude != null
                },
            })
            .integer("Número de documento inválido")
            .positive("Número de documento inválido")
            .max(99999999, "El campo no puede superar los 8 dígitos")
            .required("El número de documento es requerido"),

    })
);    