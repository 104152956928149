import { Chip, makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { downloadCsv } from "../../../../utils/filesUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import {
  localization,
  useTableStyles,
} from "../../../commons/table/config/tableConfig";
import DetailsTable from "../../../commons/table/utilsTable/detailsTableAccidents";
import TitleContainer from "../../../commons/table/utilsTable/titleWithFetchData";
const useStyles = makeStyles({
  actionButton: {
    width: "10px",
  },
  detailsTableContainer: {
    backgroundColor: "#fafafa",
  },
  errorChip: {
    borderColor: "#f37c578f",
    color: "white",
    backgroundColor: "#FF9A78",
    fontFamily: "Roboto, sans-serif",
  },
  successChip: {
    borderColor: "green",
    backgroundColor: "#00800094",
    color: "white",
    fontFamily: "Roboto, sans-serif",
  },
});

const AccidentArtTable = ({
  data,
  loading,
  noRefresh,
  fetchData,
  handleAccidentDetail,
  displayAccidents,
  handleEdit,
  handleOpenLogsDialog,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles(displayAccidents)();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const roles = useSelector((state) => state.auth.roles);

  const columns = [
    {
      title: "Prestador",
      field: "prestador",
      width: "20%",
    },
    {
      title: "Estado",
      field: "estado",
      width: "15%",
    },
    {
      title: "Siniestro",
      field: "siniestro",
      width: "10%",
      render: (rowData) => {
        if (rowData.siniestro === "0") return " ";
        return rowData.siniestro;
      },
    },
    { title: "Documento", field: "documento", width: "10%" },
    {
      title: "Trabajador",
      field: "trabajador",
      width: "20%",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
      render: (rowData) => {
        return rowData.apellidoTrabajador + ", " + rowData.nombreTrabajador;
      },
    },
    {
      title: "Accidente",
      field: "fechaHoraAccidente",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (
          moment(rowData.fechaHoraAccidente).format("DD/MM/YYYY").includes(term)
        )
          return true;
        return false;
      },
      render: (rowData) =>
        moment(rowData.fechaHoraAccidente).format("DD/MM/YYYY"),
    },
    {
      title: "Próximo Control",
      field: "proximoControl",
      width: "10%",
      cellStyle: {
        textAlign: "center",
      },
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.proximoControl).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => {
        if (rowData.proximoControl === null) {
          return "";
        }
        const diff = moment(rowData.proximoControl).diff(Date.now(), "days");

        return (
          <Chip
            variant="outlined"
            label={moment(rowData.proximoControl).format("DD/MM/YYYY")}
            className={diff < 0 ? classes.errorChip : classes.successChip}
          />
        );
      },
    },
  ];

  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      fila.siniestro ? `"${fila.siniestro}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.nombreTrabajador || fila.apellidoTrabajador
        ? (fila.nombreTrabajador !== "---" ? fila.nombreTrabajador : "") +
          (fila.apellidoTrabajador !== "---" ? fila.apellidoTrabajador : "")
        : "",
      fila.empleador ? `"${fila.empleador}"` : "",
      moment(fila.fechaHoraAccidente).format("L") || "",
      moment(fila.proximoControl).format("L") || "",
      fila.administrador ? `"${fila.administrador}"` : "",
      fila.cuitEmpleador ? `"${fila.cuitEmpleador}"` : "",
      fila.fechaAlta ? `"${fila.fechaAlta}"` : "",
      fila.fechaPrimeraCuracion
        ? moment(fila.fechaPrimeraCuracion).format("L")
        : "",
      fila.estado ? `"${fila.estado}"` : "",
    ]);

    return tableDataWithFormat;
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    const columnsHeader = [
      "Art",
      "Siniestro",
      "Documento",
      "Trabajador",
      "Empleador",
      "Accidente",
      "Próximo Control",
      "Administrador",
      "CuitEmpleador",
      "Alta",
      "Primera Curación",
      "Estado",
    ];

    const formattedDataAsCsv = [
      columnsHeader,
      ...formatTableDataForExport(data),
    ];

    let csvContent = "sep=,\n";
    csvContent += `${formattedDataAsCsv.map((e) => e.join(",")).join("\n")}`;

    downloadCsv(csvContent, "Accidentes");
    snackbarUtils.success("Los siniestros han sido descargados con éxito.");
    setIsDownloadingCsv(false);
  };

  return (
    <div className={`${tableClasses.container} ${tableClasses.tablePadding}`}>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={loading || isDownloadingCsv}
        locale="fr"
        title={
          <TitleContainer
            title="Siniestros"
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        }
        actions={[
          (rowData) => ({
            icon:
              rowData.metodoCarga === "M" ? "edit" : () => <EditOutlinedIcon />,
            tooltip: rowData.editable
              ? "Editar"
              : "No es posible editar el siniestro",
            onClick: (event, rowData) => handleEdit(rowData),
            hidden: roles.tipo !== "Soporte", // Solo visible si roles.tipo es "Soporte"
          }),
          (rowData) => ({
            icon: () => <ErrorOutlineIcon />,
            tooltip: "Registros de edicion",
            onClick: (event, rowData) => handleOpenLogsDialog(rowData),
            hidden: roles.tipo !== "Soporte", // Solo visible si roles.tipo es "Soporte"
          }),
          {
            icon: "visibility",
            tooltip: "Ver detalles",
            onClick: (event, rowData) => {
              handleAccidentDetail(rowData);
            },
            className: classes.actionButton,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          search: false,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          filtering: true,
          exportButton: true,
          exportCsv: (columns, dataExport) => {
            handleExportToCsv(dataExport);
          },
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay siniestros que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
        detailPanel={[
          {
            tooltip: "Más Información",
            render: (rowData) => (
              <div className={classes.detailsTableContainer}>
                <DetailsTable rowData={rowData} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AccidentArtTable;
