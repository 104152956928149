import { makeStyles, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    cursor: "pointer",
  },
  cardActive: {
    backgroundColor: theme.palette.primary.main,
  },
  date: {
    alignSelf: "flex-end",
    color: "grey",
  },
  textActive: {
    color: "white",
  },
  cardDisabled: {
    cursor: "default",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
}));

const FormContainerCards = ({
  data,
  selectedDocument,
  changeSelectedDocument,
  disableChange,
  commonProperties,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {data.map((document) =>

        true ? (
          <Paper
            className={`${selectedDocument.nroFormulario === document.nroFormulario && (!document.siniestro || (document.siniestro === commonProperties.idSiniestro))
              ? `${classes.card} ${classes.cardActive}`
              : classes.card
              } ${disableChange &&
                selectedDocument.nroFormulario !== document.nroFormulario
                ? classes.cardDisabled
                : null
              }`}
            key={`${document.nroFormulario} ${document.siniestro}`}
            onClick={
              disableChange ? null : () => changeSelectedDocument(document)
            }>
            <Typography
              variant="body2"
              className={
                selectedDocument.nroFormulario === document.nroFormulario && (!document.siniestro || (document.siniestro === commonProperties.idSiniestro))
                  ? classes.textActive
                  : null
              }>
              {document.nombreFormulario || document.nombre}
            </Typography>
            <Typography
              variant="body2"
              className={
                selectedDocument.nroFormulario === document.nroFormulario && (!document.siniestro || (document.siniestro === commonProperties.idSiniestro))
                  ? `${classes.date} ${classes.textActive}`
                  : classes.date
              }>
              {moment(document.fechaHoraFormulario).format("DD/MM/YYYY")}
            </Typography>
          </Paper>
        ) : null,
      )}
    </div>
  );
};

export default FormContainerCards;
