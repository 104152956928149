import { Dialog, makeStyles, Typography } from "@material-ui/core";
import MicIcon from "@mui/icons-material/Mic";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgb(70, 70, 70, 1)",
  },
  mic: {
    marginTop: 20,
    width: 100,
    height: 100,
    color: theme.palette.primary.main,
  },
  transcript: {
    margin: 20,
  },
}));

const VoiceVisualization = ({ listening, transcript }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={listening}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      PaperProps={{ className: classes.paperContainer }}>
      <MicIcon className={classes.mic} />
      <Typography color="secondary" variant="h6">
        Escuchando...
      </Typography>
      <Typography
        color="primary"
        variant="body1"
        className={classes.transcript}>
        {transcript}
      </Typography>
    </Dialog>
  );
};

export default VoiceVisualization;
