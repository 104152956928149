import {
  APPOINTMENT_ASSIST,
  CLEAR_ACCIDENT_DASHBOARD,
  CLEAR_APPOINTMENT_ASSIST,
  CLEAR_APPOINTMENT_IN_RANGE,
  GET_APPOINTMENTS_CALENDAR,
  GET_ATTENTIONS_IN_ANOTHER_PROVIDER,
  GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML,
  GET_AVAILABLE_DOCUMENTS_TO_ADD,
  GET_CLINIC_HISTORY_HTML,
  GET_DOCUMENTS,
  GET_PRE_PRINTED_FORMS,
  GET_PRE_PRINTED_HTML,
  OPEN_APPOINTMENTS_GRID,
  SET_SELECTED_ACCIDENT,
} from "../actions/accidentDashboard";

const INITIAL_STATE = {
  accident: {},
  availableDocumentsToAdd: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  documents: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  prePrintedForms: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  prePrintedHTML: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  attentionsInAnotherProvider: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  attentionInAnotherProviderHTML: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  appointmentsCalendar: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  fromAnotherProviderAppointments: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  clinicHistoryHTML: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  appointmentsPostForm: {
    isOpen: false,
    wasSent: false,
    data: {},
  },
};

const accidentDashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_APPOINTMENT_ASSIST:
      return {
        ...state,
        appointmentsPostForm: {
          ...state.appointmentsPostForm,
          wasSent: false,
        },
      };
    case APPOINTMENT_ASSIST:
      return {
        ...state,
        appointmentsPostForm: {
          ...state.appointmentsPostForm,
          wasSent: true,
        },
      };
    case CLEAR_APPOINTMENT_IN_RANGE:
      return {
        ...state,
        appointmentsPostForm: {
          ...state.appointmentsPostForm,
          isOpen: false,
        },
      };
    case OPEN_APPOINTMENTS_GRID:
      return {
        ...state,
        appointmentsPostForm: {
          ...state.appointmentsPostForm,
          isOpen: true,
          data: action.payload,
        },
      };
    case SET_SELECTED_ACCIDENT:
      return {
        ...state,
        accident: action.payload,
      };
    case `${GET_AVAILABLE_DOCUMENTS_TO_ADD}_REJECTED`:
      return {
        ...state,
        availableDocumentsToAdd: {
          ...state.availableDocumentsToAdd,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_AVAILABLE_DOCUMENTS_TO_ADD}_PENDING`:
      return {
        ...state,
        availableDocumentsToAdd: {
          ...state.availableDocumentsToAdd,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_AVAILABLE_DOCUMENTS_TO_ADD}_FULFILLED`:
      return {
        ...state,
        availableDocumentsToAdd: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_DOCUMENTS}_REJECTED`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_DOCUMENTS}_PENDING`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_DOCUMENTS}_FULFILLED`:
      return {
        ...state,
        documents: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_PRE_PRINTED_FORMS}_REJECTED`:
      return {
        ...state,
        prePrintedForms: {
          ...state.prePrintedForms,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_PRE_PRINTED_FORMS}_PENDING`:
      return {
        ...state,
        prePrintedForms: {
          ...state.prePrintedForms,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_PRE_PRINTED_FORMS}_FULFILLED`:
      return {
        ...state,
        prePrintedForms: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_PRE_PRINTED_HTML}_REJECTED`:
      return {
        ...state,
        prePrintedHTML: {
          ...state.prePrintedHTML,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_PRE_PRINTED_HTML}_PENDING`:
      return {
        ...state,
        prePrintedHTML: {
          ...state.prePrintedHTML,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_PRE_PRINTED_HTML}_FULFILLED`:
      return {
        ...state,
        prePrintedHTML: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data.documentoHTML,
        },
      };
    case `${GET_ATTENTIONS_IN_ANOTHER_PROVIDER}_REJECTED`:
      return {
        ...state,
        attentionsInAnotherProvider: {
          ...state.attentionsInAnotherProvider,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ATTENTIONS_IN_ANOTHER_PROVIDER}_PENDING`:
      return {
        ...state,
        attentionsInAnotherProvider: {
          ...state.attentionsInAnotherProvider,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ATTENTIONS_IN_ANOTHER_PROVIDER}_FULFILLED`:
      return {
        ...state,
        attentionsInAnotherProvider: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML}_REJECTED`:
      return {
        ...state,
        attentionInAnotherProviderHTML: {
          ...state.attentionInAnotherProviderHTML,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML}_PENDING`:
      return {
        ...state,
        attentionInAnotherProviderHTML: {
          ...state.attentionInAnotherProviderHTML,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ATTENTION_IN_ANOTHER_PROVIDER_HTML}_FULFILLED`:
      return {
        ...state,
        attentionInAnotherProviderHTML: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data.documentoHTML,
        },
      };
    case `${GET_CLINIC_HISTORY_HTML}_REJECTED`:
      return {
        ...state,
        clinicHistoryHTML: {
          ...state.clinicHistoryHTML,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_CLINIC_HISTORY_HTML}_PENDING`:
      return {
        ...state,
        clinicHistoryHTML: {
          ...state.clinicHistoryHTML,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_CLINIC_HISTORY_HTML}_FULFILLED`:
      return {
        ...state,
        clinicHistoryHTML: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data.documentoHTML,
        },
      };
    case `${GET_APPOINTMENTS_CALENDAR}_REJECTED`:
      return {
        ...state,
        [action.meta.storeName]: {
          ...state[action.meta.storeName],
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_APPOINTMENTS_CALENDAR}_PENDING`:
      return {
        ...state,
        [action.meta.storeName]: {
          ...state[action.meta.storeName],
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_APPOINTMENTS_CALENDAR}_FULFILLED`:
      return {
        ...state,
        [action.meta.storeName]: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case CLEAR_ACCIDENT_DASHBOARD:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default accidentDashboard;
