import axios from "axios";

export const APPOINTMENT_SESSION_DOWNLOAD = "APPOINTMENT_SESSION_DOWNLOAD";
export const APPOINTMENT_SESSION_BASE64 = "APPOINTMENT_SESSION_BASE64";

export const getAppointmentSessionReportBase64 =
  (turnoSiniestroIds, idArt) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;

    const { roles } = getState().auth;

    const mlCloudCoreArtId = roles.SOPORTE ? idArt : accident.mlCloudCoreArtId;
    dispatch({
      type: APPOINTMENT_SESSION_BASE64,
      payload: axios
        .post(
          `report/appointmentSession/base64?idArt=${mlCloudCoreArtId}`,
          turnoSiniestroIds,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const downloadAppointmentSessionReport =
  (turnoSiniestroIds, idArt) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;
    const { roles } = getState().auth;

    const mlCloudCoreArtId = roles.SOPORTE ? idArt : accident.mlCloudCoreArtId;
    return dispatch({
      type: APPOINTMENT_SESSION_DOWNLOAD,
      payload: axios
        .post(
          `report/appointmentSession/download?idArt=${mlCloudCoreArtId}`,
          turnoSiniestroIds,
          {
            responseType: "blob",
          },
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };
