import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ActivateAccountContainer from "../ui/activateAccount/activateAccountContainer";
import PublicAttendance from "../ui/appointment/attendance/publicAttendance/publicAttendance";
import ChangePasswordContainer from "../ui/changePassword/changePasswordContainer";
import LoginContainer from "../ui/login/loginContainer";
import PasswordRecoveryContainer from "../ui/passwordRecovery/passwordRecoveryContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
  },
}));

const PublicRoutes = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Routes>
        <Route
          path="/account/passwordRecovery"
          element={<PasswordRecoveryContainer />}
        />
        <Route
          path="/account/resetPassword"
          element={<ChangePasswordContainer />}
        />
        <Route path="/account/verify" element={<ActivateAccountContainer />} />
        <Route path="/account/login" element={<LoginContainer />} />
        <Route path="/attendance" element={<PublicAttendance />} />
        <Route path="*" element={<Navigate to="/account/login" />} />
      </Routes>
    </div>
  );
};

export default PublicRoutes;
