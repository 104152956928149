import { Button, makeStyles, Typography } from "@material-ui/core";
import { Info, Visibility } from "@material-ui/icons";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { getFormatoFechaExportacion } from "../../../utils/datetimeUtils";
import { downloadCsv } from "../../../utils/filesUtils";
import snackbarUtils from "../../../utils/snackbarUtils";
import {
  localization,
  useTableStyles,
} from "../../commons/table/config/tableConfig";
import TitleContainer from "../../commons/table/utilsTable/titleWithFetchData";

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: 5,
  },
  customToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  selectedMessage: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  toolbarButton: {
    marginLeft: theme.spacing(2),
  },
}));

const NovedadesTable = ({
  handleOpenEdit,
  setSelectedRows,
  namesArts,
  handleOpenRespuestas,
  handleSubmitSelected,
  data,
  loading,
  fetchData,
  noRefresh,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles()();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const columns = [
    {
      title: "Art",
      field: "art",
      width: "10%",
      lookup: namesArts,
      render: (rowData) => rowData.nombreArt.toUpperCase(),
      filtering: !!namesArts,
    },
    { title: "Siniestro", field: "historiaClinica" },
    { title: "Documento", field: "documento" },
    { title: "Trabajador", field: "trabajador" },
    { title: "Empleador", field: "empleador" },
    { title: "Prestador", field: "prestador" },
    {
      title: "Fecha Formulario",
      field: "fechaFormulario",
      render: (rowData) =>
        rowData.fechaFormulario
          ? moment(rowData.fechaFormulario).format("DD/MM/YYYY")
          : "N/A",
    },
    { title: "Formulario", field: "formulario" },
    {
      title: "Fecha Procesado",
      field: "fechaProcesado",
      render: (rowData) =>
        rowData.fechaProcesado
          ? moment(rowData.fechaProcesado).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      title: "Acciones",
      field: "acciones",
      render: (rowData) => (
        <div>
          <Info
            className={classes.icon}
            onClick={() => handleOpenRespuestas(rowData)}
          />
          <Visibility
            className={classes.icon}
            onClick={() => handleOpenEdit(rowData)}
          />
        </div>
      ),
      width: 50,
      filtering: false,
    },
  ];

  const [selectedRows, setSelectedRowsState] = React.useState([]);
  const tableRef = React.useRef();

  const handleOKClick = () => {
    handleSubmitSelected(selectedRows); // Enviar datos seleccionados
    setSelectedRows([]); // Limpiar selección en el componente padre
    setSelectedRowsState([]); // Limpiar estado interno

    // Deseleccionar filas visualmente en la tabla
    if (tableRef.current) {
      tableRef.current.onAllSelected(false); // Deseleccionar todas las filas
    }
  };
  const formatTableDataForExport = (table) => {
    return table.map((fila) => [
      // Limpiar los espacios innecesarios y formatear los datos
      fila.nombreArt ? `"${fila.nombreArt.trim()}"` : "",
      fila.historiaClinica ? `"${fila.historiaClinica.trim()}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.trabajador ? `"${fila.trabajador.trim()}"` : "",
      fila.empleador ? `"${fila.empleador.trim()}"` : "",
      fila.prestador ? `"${fila.prestador.trim()}"` : "",
      getFormatoFechaExportacion(fila.fechaFormulario, "P") || "", // Aseguramos que la fecha sea válida
      fila.formulario ? `"${fila.formulario.trim()}"` : "",
      getFormatoFechaExportacion(fila.fechaProcesado, "P") || "", // Aseguramos que la fecha sea válida
    ]);
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    // Encabezado correcto según tu ejemplo
    const encabezado = [
      "Art",
      "Siniestro",
      "Documento",
      "Trabajador",
      "Empleador",
      "Prestador",
      "Fecha Formulario",
      "Formulario",
      "Fecha Procesado",
    ];

    // Formateamos los datos de la tabla
    const formattedDataAsCsv = [encabezado, ...formatTableDataForExport(data)];

    // Generamos el contenido CSV
    let csvContent = "sep=,\n"; // Definimos el separador
    csvContent += `${formattedDataAsCsv.map((e) => e.join(",")).join("\n")}`; // Unimos las filas por saltos de línea

    // Llamamos a la función para descargar el archivo CSV
    downloadCsv(csvContent, "Novedades");
    snackbarUtils.success("Las novedades han sido descargados con éxito.");
    setIsDownloadingCsv(false);
  };

  return (
    <div className={`${tableClasses.tablePadding}`}>
      <MaterialTable
        tableRef={tableRef} // Referencia a la tabla
        columns={columns}
        title={
          <TitleContainer
            title="Novedades"
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        }
        data={data}
        isLoading={loading || isDownloadingCsv}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: true,
          // eslint-disable-next-line no-shadow
          exportCsv: (columns, dataExport) => {
            handleExportToCsv(dataExport);
          },
        }}
        onSelectionChange={(rows) => {
          setSelectedRows(rows); // Actualizar filas seleccionadas en el padre
          setSelectedRowsState(rows); // Actualizar filas seleccionadas localmente
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay registros que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
        components={{
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props} />{" "}
              {selectedRows.length > 0 && (
                <div className={classes.customToolbar}>
                  <Typography
                    variant="subtitle1"
                    className={classes.selectedMessage}>
                    {selectedRows.length} elemento(s) seleccionado(s)
                  </Typography>

                  <Button
                    className={classes.toolbarButton}
                    variant="contained"
                    color="secondary"
                    onClick={handleOKClick}>
                    OK
                  </Button>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  );
};

export default NovedadesTable;
