import {
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core/";
import TextFieldMUI from "@material-ui/core/TextField";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  iconError: {
    color: red[400],
  },
}));

export default function TextField({
  setFieldValue,
  fieldValue,
  placeholder,
  rules,
  valueToCompare,
  validateInput,
}) {
  const classes = useStyles();

  const [error, setError] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);

  const validateRules = (value, touched = false) => {
    let errors = [];

    if (!value) errors = ["Campo requerido"];
    else if (touched || isTouched) {
      rules.forEach((rule) => {
        if (rule === "max" && value.length > 32) {
          errors = ["Debe tener 32 carácteres o menos", ...errors];
        }
        if (rule === "min" && value.length < 8) {
          errors = [
            ...errors,
            "La contraseña debe tener al menos 8 carácteres",
          ];
        }
        if (
          rule === "alphanumeric" &&
          !/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9~!@#$%^&*_\-+=|(){}[\]:;'<>,.?/]+$/.test(
            value,
          )
        ) {
          errors = ["La contraseña debe contener números y letras", ...errors];
        }
        if (rule === "match" && value !== valueToCompare) {
          errors = ["Las contraseñas no coinciden", ...errors];
        }
      });
    }

    if (
      (value === valueToCompare || touched || isTouched) &&
      errors.length === 0
    )
      validateInput(true);
    else validateInput(false);

    setError(errors);
  };

  const handleChange = (event) => {
    if (event.target.value) setError(false);

    setFieldValue(event.target.value);
    validateRules(event.target.value, true);
  };

  const validateEmptyField = (event) => {
    setIsTouched(true);
    validateRules(event.target.value, true);
  };

  return (
    <FormControl className={classes.formControl}>
      <TextFieldMUI
        variant="standard"
        value={fieldValue}
        onChange={handleChange}
        onBlur={validateEmptyField}
        error={error.length > 0}
        placeholder={placeholder}
        label={placeholder}
        fullWidth
        type="password"
        endAdornment={
          <InputAdornment position="end">
            {error.length > 0 && <ErrorIcon className={classes.iconError} />}
          </InputAdornment>
        }
      />
      {error.length > 0 && <FormHelperText error>{error[0]}</FormHelperText>}
    </FormControl>
  );
}
