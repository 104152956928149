import { makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttentionsInAnotherProvider } from "../../../../../actions/accidentDashboard";
import {
  openEditForm,
  setFormsDocumentList,
  setFormsGeneralProperties,
} from "../../../../../actions/formsState";
import { parseHTMLToReact } from "../../../../../utils/util";
import PrintAndDownloadDialog from "../../../../commons/dialog/PrintAndDownloadDialog";
import { formBehaviourEnum } from "../../../../commons/enum/formEnums";
import CardTitle from "../cardTitle";
import AttentionsInAnotherProviderCards from "./attentionsInAnotherProviderCards";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AttentionsInAnotherProvider = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const attentions = useSelector(
    (state) => state.accidentDashboard.attentionsInAnotherProvider,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const attentionHTML = useSelector(
    (state) => state.accidentDashboard.attentionInAnotherProviderHTML,
  );

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(
      getAttentionsInAnotherProvider(
        accident.idSiniestro,
        accident.mlCloudCoreArtId,
      ),
    );
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDetail = (document, error = {}) => {
    dispatch(setFormsDocumentList(document.formularios));
    dispatch(
      setFormsGeneralProperties({
        idSiniestro: document.formularios[0].siniestro,
        idArt: accident.mlCloudCoreArtId,
        nombreTrabajador: accident.nombreTrabajador,
        apellidoTrabajador: accident.apellidoTrabajador,
        numeroHistoriaClinica: document.historiaClinica,
        artName: accident.art,
        documento: accident.documento,
        idProvider: document.formularios[0].prestador,
      }),
    );
    dispatch(
      openEditForm(
        document.formularios[0].comportamiento?.trim(),
        formBehaviourEnum.ATTENTION_IN_OTHER_PROVIDER,
        {
          nroFormulario: document.formularios[0].nroFormulario,
        },
        {},
      ),
    );
  };

  return (
    <div className={classes.container}>
      <CardTitle title="ATENCIONES EN OTRO PRESTADOR" color="primary" />
      {!accident.permiteOtrosPrestadores ? (
        <Box
          mt={5}
          display="flex"
          justifyContent="center"
          alignContent="center">
          <Typography>No se permiten atenciones en otro prestador</Typography>
        </Box>
      ) : (
        <AttentionsInAnotherProviderCards
          data={attentions.data}
          loading={attentions.isFetching}
          generateAttentionInAnotherProviderHTML={handleOpenDetail}
        />
      )}
      <PrintAndDownloadDialog
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        childComponent={
          <React.Fragment>
            {parseHTMLToReact(attentionHTML.data)}
          </React.Fragment>
        }
        loading={attentionHTML.isFetching || !openModal}
        title="ATENCIÓN EN OTRO PRESTADOR"
        showDownloadButton={false}
        reactToPrintProps={{ suppressErrors: true }}
      />
    </div>
  );
};

export default AttentionsInAnotherProvider;
